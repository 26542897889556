const files = [
  'limit-triple-draw-won.json',
  'nl-single-draw-7-max-tournament.json',
  'no-limit-single-draw-no-hero.json',
  'no-limit-single-draw-short-handed-3-way-showdown.json',
  'no-limit-triple-draw-shorthanded-lost.json',
  'pot-limit-triple-draw-hu.json',
]

const result = {}

files.forEach((k) => {
  result[k] = require(`./${k}`)
})

module.exports = result
