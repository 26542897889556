import { html } from 'lit-html'

import './playing-card'

import '../../../../lib/assets/source/replayer/deck/reference/reference.scss'

export default {
  title: 'Components/playing-card',
  argTypes: {
    suit: { control: 'inline-radio', options: ['c', 'd', 'h', 's', 'x'] },
    rank: { control: 'inline-radio', options: ['A', '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'x'] },
    size: { control: 'inline-radio', options: ['small', 'medium', 'large'] },
  },
  args: {
    suit: 's',
    rank: 'A',
    size: 'medium',
    discarded: false,
    blank: false,
    simple: false,
    hole: false,
    folded: false,
    noWin: false,
    fourColor: false,
    showReference: false
  },
}

export const Default = ({ data, ...args }) => (
  html`<playing-card
      suit=${args.suit}
      rank=${args.rank}
      size=${args.size}
      ?discarded=${args.discarded}
      ?blank=${args.blank}
      ?simple=${args.simple}
      ?hole=${args.hole}
      ?folded=${args.folded}
      ?no-win=${args.noWin}
      ?four-color=${args.fourColor}
      ?show-reference=${args.showReference}
    ></playing-card>`
)
