import { html } from 'lit-html'
import Immutable from 'immutable'

import CardModel from 'replayer/models/Card'
import gameTypes, { hasCommunity } from '../storiesSupport/gameTypes'
import generateCards from '../storiesSupport/generateCards'

import './replayer-table'

import '../../../../lib/assets/source/replayer/table/reference/reference.scss'

const generateSeat = (gameClass, seat, args) => ({
  bounty: args.bounty,
  chipsOut: args.chipsOut,
  name: `Seat ${seat + 1}`,
  action: args.action,
  cards: generateCards(gameClass),
  currentStack: args.currentStack,
  folded: args.folded,
  hero: args.hero === seat + 1
})

const generateCommunity = (gameClass, args) => {
  const states = { spit: 1, flop: 3, turn: 4, river: 5 }
  let community = { first: [], second: [] }
  if (hasCommunity(gameClass)) {
    const street = args.street
    // console.log(street, states[street])
    community.first = Array.from({ length: states[street] }, (_, x) => CardModel.random())
    if (args.runTwice) {
      community.second = Array.from({ length: states[street] }, (_, x) => CardModel.random())
    }
  }
  return community
}

const generateAction = (gameClass, seats, args) => ({
  street: 'foo',
  index: 1,
  community: generateCommunity(gameClass, args),
  seats: Array.from({ length: seats }, (_, i) => generateSeat(gameClass, i, args)),
  pot: generatePot()
})

const generatePot = () => {
  const main = 234567
  const side1 = 53764
  const side2 = 75834
  const side3 = 51142
  const side4 = 12465

  return Immutable.Map({
    totalPot: main + side1 + side2 + side3 + side4,
    displayPots: Immutable.Map({
      main,
      side1,
      side2,
      // side3,
      // side4
    })
  })
}

export default {
  title: 'Components/replayer-table'
}

export const Basic = ({ data, ...args }) => {
  const gameClass = args.gameType
  const seats = args.seats
  const action = generateAction(gameClass, seats, args)
  const button = Math.min(args.button, seats)

  const currency = args.currency
  const gameSlug = gameClass
  let gameLabel = ''
  switch (args.gameType) {
    case 'holdem':
      gameLabel = 'No Limit Hold\'em'
      break
    case 'badugi':
      gameLabel = 'Limit Badugi'
      break
    case 'courchevel':
      gameLabel = 'Pot Limit Courchevel'
      break
    case 'deuce-to-seven':
      gameLabel = 'Limit 2-7 Triple Draw'
      break
    case 'five-card-draw':
      gameLabel = 'Limit 5 Card Draw'
      break
    case 'five-card-omaha':
      gameLabel = 'Pot Limit 5 Card Omaha'
      break
    case 'four-card-omaha':
      gameLabel = 'Pot Limit Omaha'
      break
    case 'seven-card-stud':
      gameLabel = 'Limit Seven Card Stud'
      break
  }
  return html`
    <replayer-table
      .action=${action}
      size=${seats}
      button=${button}
      currency=${currency}
      game-class=${gameClass}
      game-slug=${gameSlug}
      game-type=${args.gameType}
      game-label=${gameLabel}
      ?will-run-twice=${args.runTwice}
      ?show-reference=${args.showReference}
      tournament-currency=${args.tournamentCurrency}
      style="--reference-opacity: ${args.referenceOpacity}"
    ></replayer-table>
  `
}

Basic.argTypes = {
  action: {
    control: { type: 'select' },
    options: ['check', 'raise', 'bring_in', 'stand_pat', 'won', 'call', 'fold', '']
  },
  button: {
    control: { type: 'number', min: -1, max: 10 }
  },
  hero: {
    control: { type: 'number', min: 1, max: 10 }
  },
  currency: {
    control: { type: 'select' },
    options: ['$', '£', '€', null]
  },
  gameType: {
    control: { type: 'select' },
    options: Object.keys(gameTypes)
  },
  seats: {
    control: { type: 'select' },
    options: [2, 3, 4, 5, 6, 7, 8, 9, 10]
  },
  street: {
    control: { type: 'select' },
    options: ['spit', 'flop', 'turn', 'river']
  },
  runTwice: {
    control: { type: 'boolean' }
  },
  size: { control: false },
  gameClass: { control: false },
  gameSlug: { control: false },
  scale: { control: false },
  tournamentCurrency: {
    control: { type: 'select' },
    options: ['$', '£', '€', null]
  },
  owner: { control: false },
  referenceOpacity: { control: { type: 'range', min: 0.1, max: 1, step: 0.1 } }
}

Basic.args = {
  showReference: false,
  referenceOpacity: 0.5,
  action: 'check',
  bounty: 1100,
  chipsOut: 1250,
  currentStack: 12555,
  gameType: 'holdem',
  seats: 6,
  currency: '$',
  folded: false,
  hero: 1,
  button: -1,
  runTwice: false,
  street: 'river',
}
