import { Controller } from '@hotwired/stimulus'

/*
 * Handles persistence of transient editable tokens to local storage so
 * they can be used to permit editing.
 */
export default class extends Controller {
  connect() {
    if (!this.data.has('saved') && this.data.has('key') && this.data.has('token')) {
      window.localStorage.setItem(this.data.get('key'), this.data.get('token'))
      this.data.set('saved')
    }
  }

  delete(e) {
    if (this.data.has('key')) {
      window.localStorage.removeItem(this.data.get('key'))
    }
  }
}
