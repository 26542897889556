import { html, LitElement } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import numberFormat from 'replayer/lib/numberFormat'

import './money-label'
import './playing-cards'
import './poker-chips'
import './seat-pod'

import 'replayer/stylesheets/table.scss'

const LEFT = 'left'
const RIGHT = 'right'
const RIGHT_REVERSE_CHIPS = 'right_reverse_chips'

const SEAT_LAYOUTS = {
  2: [LEFT, { desktop: RIGHT, mobile: RIGHT_REVERSE_CHIPS }],
  3: [LEFT, RIGHT_REVERSE_CHIPS, { desktop: RIGHT, mobile: RIGHT_REVERSE_CHIPS }],
  4: [LEFT, LEFT, RIGHT_REVERSE_CHIPS, { desktop: RIGHT, mobile: RIGHT_REVERSE_CHIPS }],
  5: [LEFT, LEFT, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, { desktop: LEFT, mobile: RIGHT_REVERSE_CHIPS }],
  6: [LEFT, LEFT, LEFT, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, { desktop: RIGHT, mobile: RIGHT_REVERSE_CHIPS }],
  7: [LEFT, LEFT, LEFT, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, { desktop: RIGHT, mobile: RIGHT_REVERSE_CHIPS }],
  8: [LEFT, LEFT, LEFT, LEFT, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, { desktop: RIGHT, mobile: RIGHT_REVERSE_CHIPS }],
  9: [LEFT, LEFT, LEFT, LEFT, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, { desktop: LEFT, mobile: RIGHT_REVERSE_CHIPS }],
  10: [LEFT, LEFT, LEFT, LEFT, LEFT, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, RIGHT_REVERSE_CHIPS, { desktop: RIGHT, mobile: RIGHT_REVERSE_CHIPS }],
}

@customElement('replayer-table')
export class ReplayerTable extends LitElement {
  @property({ type: Object })
  action = null

  // note can accept -1 to show button on each seat for stories
  @property({ type: Number })
  button = 0

  @property({ type: String })
  currency = ''

  @property({ type: String, attribute: 'game-class' })
  gameClass = ''

  @property({ type: String, attribute: 'game-type' })
  gameType = ''

  @property({ type: String, attribute: 'game-slug' })
  gameSlug = ''

  @property({ type: String, attribute: 'game-label' })
  gameLabel = ''

  @property({ type: Number })
  size = 0

  @property({ type: String, attribute: 'tournament-currency' })
  tournamentCurrency = ''

  @property({ type: Boolean, attribute: 'will-run-twice' })
  willRunTwice = false

  @property({ type: Boolean, attribute: 'with-community', reflect: true })
  withCommunity = false

  @property({ type: String, attribute: 'table-mode', reflect: true})
  _tableMode = ''

  @state()
  _tableScale = 1

  @state()
  _mobile = false

  createRenderRoot() {
    return this
  }

  connectedCallback() {
    super.connectedCallback()
    this.resizeObserver = new ResizeObserver(entries => {
      const { contentRect } = entries[0]
      const style = getComputedStyle(this)
      this._tableMode = style.getPropertyValue('--table-mode').trim()
      let height = contentRect.height
      this._mobile = this._tableMode.match(/mobile/)

      if (this._mobile) {
        // on mobile ensure table fits in viewport
        const mobileBrowserChromeHeight = 100
        height = Math.min(height, window.innerHeight - mobileBrowserChromeHeight)
      }

      const tableDimensions = {
        width: parseInt(style.getPropertyValue('--table-width'), 10),
        height: parseInt(style.getPropertyValue('--table-height'), 10)
      }

      const scaleX = contentRect.width / tableDimensions.width
      const scaleY = height / tableDimensions.height

      this._tableScale = Math.min(scaleX, scaleY)
    })

    this.resizeObserver.observe(this)
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.resizeObserver.disconnect()
    this.resizeObserver = null
  }

  updated(changedProperties) {
    if (changedProperties.has('gameClass')) {
      this.withCommunity = ['holdem', 'five-card-omaha', 'four-card-omaha'].includes(this.gameClass)
    }
  }

  get community() {
    const { community } = this.action
    const classes = classMap({
      'community-cards': true,
      'replayer-table__community-cards': true,
      'will-run-twice': this.willRunTwice,
    })

    return html`
      <div class=${classes}>
        <div class="first-run">
          <playing-cards .cards=${community.first} size="large" community></playing-cards>
        </div>
        <div class="second-run">
          <playing-cards .cards=${community.second} size="large" community></playing-cards>
        </div>
        ${this.runTwiceLabel}
      </div>
    `
  }

  potLabel(key) {
    const md = key.match(/side([0-9]+)/)
    if (md) {
      return `Side pot ${md[1]}`
    } else {
      return 'Main pot'
    }
  }

  get pots() {
    if (this.action.pot) {
      const pots = this.action.pot.get('displayPots').entrySeq()

      return html`
        <div class="replayer-table__pots">
          ${pots.map((p) => {
            const [key, val] = p
            return html`
              <div
                class=${classMap({ pot: true, [key]: true,  'side-pot': key.match('side') })}
                title=${`${this.potLabel(key)} : ${numberFormat(val, this.currency)}`}
              >
                <poker-chips
                  value=${val}
                  size="small"
                  currency=${this.currency}
                ></poker-chips>
              </div>
            `
          })}
        </div>
      `
    }
  }

  get runTwiceLabel() {
    const community = this.action.community
    if (community.second.length == 0) return null

    return html`<div class="running-twice"><p>Running it twice</p></div>`
  }

  get seatsClassName() {
    const strings = {
      2: 'two',
      3: 'three',
      4: 'four',
      5: 'five',
      6: 'six',
      7: 'seven',
      8: 'eight',
      9: 'nine',
      10: 'ten'
    }
    return `${strings[this.size]}-seats`
  }

  get totalPot() {
    const { pot } = this.action
    if (pot) {
      return html`<money-label value=${pot.get('totalPot')} currency=${this.currency} prefix="Pot: " class="replayer-table__total-pot"></money-label>`
    }
  }

  get seatOffset() {
    const seats = this.action.seats
    const heroIndex = seats.findIndex((s) => s.hero)
    const heroSeat = this.size

    if (heroSeat !== -1) {
      return (this.size - 1) - heroIndex
    }

    return 0
  }

  get seatsCenteredOnHero() {
    const seats = this.action.seats.map((s, ix) => ({...s, number: ix + 1}))
    const offset = this.seatOffset

    if (offset !== 0) {
      // positive offsets move right
      const pivot = (offset < 0 ? 0 : seats.length) - offset % seats.length
      return seats.slice(pivot).concat(seats.slice(0, pivot))
    }

    return seats
  }

  render() {
    const tableStyle = styleMap({ '--table-scale': this._tableScale })

    if (!this.action) {
      return html`<div class="replayer-table" style=${tableStyle} data-turbo-cache="false">
        <div class="ui active dimmer">
          <div class="ui text loader">Loading</div>
        </div>
      </div>`
    }

    const { button, currency, gameClass, seatsCenteredOnHero, tournamentCurrency, size } = this
    const seatLayout = SEAT_LAYOUTS[size] || []

    return html`
      <div class="replayer-table" style=${tableStyle} data-turbo-cache="false">
        <div class="replayer-table__logo"></div>
        ${this.totalPot}
        <p class="replayer-table__game-type">
          ${this.gameLabel}
        </p>
        ${this.community}
        ${this.pots}
        <div class="replayer-table__seats ${this.seatsClassName}">
          ${seatsCenteredOnHero.map((s, ix) => {
            let layout = seatLayout[ix]
            if (layout.mobile) {
              layout = layout[this._mobile ? 'mobile' : 'desktop']
            }

            const reverseChips = layout == RIGHT_REVERSE_CHIPS
            const actualLayout = layout == RIGHT_REVERSE_CHIPS ? RIGHT : layout

            return html`
              <div class="replayer-table__seat" seat-number="${ix + 1}">
                <seat-pod
                  action=${[s.action, s.value].join(':')}
                  ?button=${button == -1 || button === s.number}
                  bounty=${s.bounty}
                  bountyCurrency=${tournamentCurrency}
                  ?cap=${s.cap}
                  .cards=${s.cards}
                  currency=${currency}
                  current-stack=${s.currentStack}
                  chips-out=${s.chipsOut}
                  ?empty=${s.empty}
                  ?folded=${s.folded}
                  game-class=${gameClass}
                  ?hero=${s.hero}
                  name=${s.name}
                  ?no-win=${s.noWin}
                  ?sat-out=${s.satOut}
                  layout=${actualLayout}
                  ?reverse-chips=${reverseChips}
                  ?cashed-out=${s.cashedOut}
                ></seat-pod>
              </div>`
          })}
        </div>
      </div>
    `
  }
}
