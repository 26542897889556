import { html, LitElement } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import normalize from 'json-api-normalizer'
import { fromJS } from 'immutable'
import { getSetting, settings } from '../lib/settings'

import text from '../../text.js.erb'

import Hand from 'replayer/models/Hand'

import 'replayer/stylesheets/handReplayer.scss'

import './replayer-table'

@customElement('hand-replayer')
export class HandReplayer extends LitElement {
  @property({ type: Boolean, attribute: 'autoplay' })
  autoPlay = false

  @property({ type: Object, attribute: false })
  hand = null

  @property({ type: String })
  guid = null

  // @TODO protected state
  @state()
  _action = null

  @state()
  _playing = false

  @state()
  _speed = 3

  connectedCallback() {
    super.connectedCallback()
    this.updateComplete.then(window.requestAnimationFrame(this.afterUpdateComplete))
    document.addEventListener('keydown', this._handleShortcuts)
    settings.on('changed:showKnownHoleCards', this.onShowKnownHoleCards)

    const speed = localStorage.getItem('replayerSpeed')
    if (speed) {
      this._speed = parseInt(speed, 10)
    }
  }

  afterUpdateComplete = () => {
    if (this.hand) { return }

    const dataScript = this.renderRoot.querySelector('script')
    if (dataScript) {
      this._loadHand(JSON.parse(dataScript.textContent))
    } else {
      this._fetchData()
    }
  }

  onShowKnownHoleCards = () => {
    if (this.hand) {
      const { currentStreet, currentIndex } = this.hand
      const newHand = this.hand.set('showKnownHoleCards', getSetting('showKnownHoleCards'))
      newHand.currentStreet = currentStreet
      newHand.currentIndex = currentIndex
      newHand.dontRewind = true
      this.hand = newHand
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener('keydown', this._handleShortcuts)
    settings.off('changed:showKnownHoleCards', this.onShowKnownHoleCards)
  }

  updated(changedProperties) {
    if (changedProperties.has('hand') && this.hand) {
      if (this.hand.dontRewind) {
        this.action = this.hand.curAction()
      } else {
        this._firstAction()
      }

      if (this.autoPlay && !this.hand.dontRewind) {
        if (matchMedia && matchMedia('only screen and (max-width: 760px), only screen and (orientation: landscape) and (max-height: 760px)').matches) {
          // don't autoplay on mobile
          return
        }
        this._play()
      }
    }

    if (changedProperties.has('guid') && changedProperties.get('guid')) {
      this._fetchData()
    }

    if (changedProperties.has('_speed')) {
      localStorage.setItem('replayerSpeed', this._speed)
      this._updatePlayTimer()
    }
  }

  set action(action) {
    this._action = action
    if (action.end) {
      this._pause()
    }
  }

  get action() {
    return this._action
  }

  _loadHand(data) {
    // accept already normalize hand (for stories)
    let source = data
    if (data.data) {
      // handle data in JSON API format
      // @TODO can we use something clearer to get this data
      const normalized = normalize(data)
      source = normalized.hands[Object.keys(normalized.hands)[0]].attributes
    }
    source.showKnownHoleCards = getSetting('showKnownHoleCards')
    this.hand = new Hand(fromJS(source))
  }

  _fetchData() {
    if (!this.guid) { return }

    fetch(`/api/hands/${this.guid}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        };
        return response.json()
      })
      .then(data => {
        this._loadHand(data)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  // @TODO private
  _firstAction() {
    this.action = this.hand.firstAction()
  }

  _prevAction(e) {
    if(e) { this._pause() } // pause when from user keyboard navigation/button click
    // @TODO fix hand.start for stud hands (first index is like 15)
    if (!this.action.start && this.action.index != -1) {
      this.action = this.hand.prevAction()
    } else {
      this.dispatchEvent(new CustomEvent('replayer:beforeStart'))
    }
  }

  _nextAction(e) {
    if(e) { this._pause() } // pause when from user keyboard navigation/button click
    if (!this.action.end) {
      this.action = this.hand.nextAction()
    } else {
      this.dispatchEvent(new CustomEvent('replayer:afterEnd'))
    }
  }

  _goToStreet(street) {
    // this.pause()
    this.action = this.hand.streetAction(street)
  }

  _goToNextStreet() {
    this.action = this.hand.moveToStreet(1)
  }

  _goToPrevStreet() {
    this.action = this.hand.moveToStreet(-1)
  }

  _togglePlay() {
    if (this._playing) {
      this._pause()
    } else {
      this._play()
    }
  }

  _pause() {
    this._clearPlayTimer()
    this._playing = false
  }

  _play() {
    if (this.action.end) {
      this._firstAction()
    }
    this._playing = true
    this._updatePlayTimer()
  }

  _updatePlayTimer() {
    if (this._playing) {
      const delay = 2400 / this._speed
      if (!this._playTimer || delay != this._lastDelay) {
        this._clearPlayTimer()
        this._playTimer = setInterval(() => this._nextAction(), delay)
      }
      this._lastDelay = delay
    }
  }

  _clearPlayTimer() {
    clearInterval(this._playTimer)
    this._playTimer = false
  }

  _moveToStreet(direction) {
    this._pause()
    this.action = this.hand.moveToStreet(direction)
  }

  _handleShortcuts = (e) => {
    if (e.target instanceof HTMLInputElement) {
      return
    }

    let handled = false

    switch(e.code) {
      case 'ArrowLeft':
        handled = true
        this._prevAction()
        break
      case 'ArrowRight':
        handled = true
        this._nextAction()
        break
      case 'ArrowDown':
        handled = true
        this._moveToStreet(-1)
        break
      case 'ArrowUp':
        handled = true
        this._moveToStreet(1)
        break
      case 'Space':
        handled = true
        this._togglePlay()
        break
    }

    if (handled) { e.preventDefault() }
  }

  _setSpeed = (e) => {
    this._speed = parseInt(e.target.value, 10)
  }

  createRenderRoot() {
    return this
  }

  get _playIcon() {
    if (this._playing) {
      return 'pause'
    }
    return this.action.end ? 'repeat' : 'play'
  }

  get _streetButtons() {
    const visible = this.hand.streetKeys.filter((k) => !k.match(/second(Flop|Turn|River)/))

    return html`
      <div class="ui big buttons street-controls">
        ${visible.map((k) => {
          const active = this.hand.currentStreet == k
          return html`<button class="ui button ${active ? 'active' : ''}" @click=${() => this._goToStreet(k)}>
            ${ text.replayer.streetLabels[k] }
          </button>`
        })}
      </div>

      <div class="ui big buttons mobile-street-controls">
        <button class="ui icon button" @click=${() => this._goToPrevStreet()}><i class="chevron left icon"></i></button>
        <button class="ui button current-street-button">${ text.replayer.streetLabels[this.hand.currentStreet] }</button>
        <button class="ui icon button" @click=${() => this._goToNextStreet()}><i class="chevron right icon"></i></button>
      </div>
    `
  }

  get _loading() {
    return html`<replayer-table></replayer-table>`
  }

  get _table() {
    const { action, hand, _nextAction, _playing, _playIcon, _prevAction, _setSpeed, _streetButtons, _togglePlay } = this
    return html`
      <replayer-table
        .action=${action}
        button=${hand.button}
        currency=${hand.currency}
        game-class=${hand.gameClass}
        game-slug=${hand.gameSlug}
        game-type=${hand.gameType}
        game-label=${hand.gameLabel}
        size=${hand.seats}
        tournament-currency=${hand.tournamentCurrency}
        ?will-run-twice=${hand.willRunTwice}
        data-turbo-cache="false"
      ></replayer-table>
      <div class="controls">
        ${_streetButtons}

        <div class="play-controls">
          <div class="keyboard-help" data-controller="popup">
            <i class="keyboard bordered icon" data-popup-target="trigger"></i>

            <div class="ui popup" data-popup-target="popup">
              <div class="header">Keyboard shortcuts</div>
              <div class="content">
                <i class="long arrow alternate left icon"></i> : Previous action<br />
                <i class="long arrow alternate right icon"></i> : Next action<br />
                <i class="long arrow alternate up icon"></i> : Next street<br />
                <i class="long arrow alternate down icon"></i> : Previous street<br />
                Spacebar : Play/Pause
              </div>
            </div>
          </div>

          <div class="speed-controls">
            <input type="range" min="1" max="5" step="1" id="speed" list="speed-steps" value=${this._speed} @input=${_setSpeed}>
          </div>

          <div class="ui big buttons action-controls">
            <button class="ui icon button" @click=${_prevAction} ?disabled=${action.start}>
              <i class="chevron left icon"></i>
            </button>
            <button
              class=${`ui button play-button -${_playIcon} ${_playing ? 'active' : ''}`}
              @click=${_togglePlay}
            >
            </button>
            <button class="ui icon button" @click=${_nextAction} ?disabled=${action.end}>
              <i class="chevron right icon"></i>
            </button>
          </div>
        </div>
      </div>
    `
  }

  render() {
    return html`
      ${!this.action ? this._loading : this._table}
    `
  }
}
