const files = [
  'et-timezone-tournament.json',
  'from-ps-cash-all-holecards-bb-sitting-out.json',
  'from-ps-cash-all-holecards-sb-sitting-out.json',
  'from-ps-hu-with-all-holecards.json',
  'from-ps-with-all-holecards.json',
  'from_email_request.json',
  'full-ring-hu-won.json',
  'full-ring-tournament-with-sitting-out-player.json',
  'nlhe-6-max-another-hero.json',
  'nlhe-6-max-ante-won-preflop.json',
  'nlhe-6-max-cap-won.json',
  'nlhe-6-max-run-twice-from-flop.json',
  'nlhe-6-max-run-twice-from-river.json',
  'nlhe-6-max-run-twice-from-turn.json',
  'nlhe-6-max-zoom-won.json',
  'nlhe-bounty-tournament.json',
  'nlhe-full-ring-tournament-multiple-side-pots.json',
  'nlhe-hu-zoom-lost.json',
  'nlhe-multiple-dead-blind-posts.json',
  'nlhe-with-one-sidepot.json',
  'nlhe-zoom-multiple-histories.json',
  'playmoney.json',
  'spin-n-go-with-whitespace-pre.json',
]

const result = {}

files.forEach((k) => {
  result[k] = require(`./${k}`)
})

module.exports = result
