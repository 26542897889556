import { html } from 'lit-html'

import './money-label'

export default {
  title: 'Components/money-label'
}

export const Default = ({ data, ...args }) => (
  html`<money-label value=${args.value} currency=${args.currency}></money-label>`
)

Default.argTypes = {
  currency: {
    control: 'select', options: ['', '$']
  }
}

Default.args = {
  value: 1000,
  currency: ''
}
