import Immutable from 'immutable'
import _ from 'lodash'

const ranks = [2,3,4,5,6,7,8,9,'T','J','Q','K','A']
const suits = ['c','d','h','s']

const CardRecord = Immutable.Record({
  rank: 'x',
  suit: 'x',
  discarded: false,
  blank: false
})

class Card extends CardRecord {
  static random(options = {discarded: false}) {
    return new Card(Object.assign(
      {rank: _.sample(ranks), suit: _.sample(suits)},
      options
    ))
  }

  static blank() {
    return new Card({blank: true})
  }

  static unknown() {
    return new Card({ rank: 'x', suit: 'x' })
  }

  toString() {
    return this.rank + this.suit
  }

  get unknown() {
    return this.rank == 'x' || this.suit == 'x'
  }
}

export default Card
