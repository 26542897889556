import Card from '../Card'
import _ from 'lodash'

export default class {
  constructor(hand) {
    this.hand  = hand
    this.state = {}
    this.lastStreet = null
    this._expandDealActions()
  }

  stateAt(street,index) {
    if(street == 'showdown') {
      street = this.lastStreet
    }
    return this.state[street] || []
  }

  _expandDealActions() {
    let community = {
      first: [],
      second: []
    }
    this.hand.streets.forEach((street, streetName) => {
      if(street.getIn(['deal', 'type']) == 'community') {
        const isSecondRun = streetName.match('second')
        let target  = community[isSecondRun ? 'second' : 'first' ]
        const cards = street.getIn(['deal', 'cards'])
        if(isSecondRun) {
          this._padSecondRunDeal(target,streetName, cards.size)
        }
        cards.forEach((c) => {
          target.push(new Card({rank: c.get('rank'), suit: c.get('suit')}))
        })
      }
      this.state[streetName] = {
        first: community.first.slice(),
        second: community.second.slice()
      }
      this.lastStreet = streetName
    })
  }

  _padSecondRunDeal(target, streetName, cardsReceivedCount) {
    // for run-it-twice from later streets we pad the community with blank cards
    const pad = { secondFlop: 0, secondFlopNoSpit: 1, secondTurn: 3, secondRiver: 4}
    if(streetName == 'secondFlop' && cardsReceivedCount == 2) {
      // we were ran twice from preflop in a spit based game (e.g. Courchevel)
      streetName = 'secondFlopNoSpit'
    }
    const expectedSecondLength = pad[streetName]
    if(target.length < expectedSecondLength) {
      _.times(expectedSecondLength - target.length, () => target.push(Card.blank()))
    }
  }
}
