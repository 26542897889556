import { html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { getSetting, settings } from '../lib/settings'

import './playing-card'

@customElement('playing-cards')
export class PlayingCards extends LitElement {
  @property({ type: Object })
  cards = []

  @property({ type: Boolean })
  folded = false

  @property({ type: Boolean, attribute: 'four-color', reflect: true })
  fourColor = false

  @property({ type: String, attribute: 'game-class' })
  gameClass = null

  @property({ type: Boolean })
  hole = false

  @property({ type: Boolean, attribute: 'no-win' })
  noWin = false

  @property({ type: Boolean, reflect: true })
  overlap = false

  @property({ type: Boolean })
  simple = false

  @property({ type: String })
  size = 'medium'

  @property({ type: Boolean, reflect: true })
  unknown = false

  connectedCallback() {
    super.connectedCallback()
    settings.on('changed:fourColorDeck', this.onFourColorDeckChange)
    this.fourColor = getSetting('fourColorDeck')
  }

  disconnectedCallback() {
    settings.off('changed:fourColorDeck', this.onFourColorDeckChange)
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('gameClass')) {
      if (this.gameClass) {
        this.overlap = !this.simple && this.gameClass !== 'holdem'
      }
    }

    if (changedProperties.has('cards')) {
      if (this.hole) {
        this.unknown = (this.cards || []).some((c) => c.unknown)
      }
    }
  }

  createRenderRoot() {
    return this
  }

  onFourColorDeckChange = (value) => {
    this.fourColor = value
  }

  render() {
    const { cards, folded, fourColor, hole, noWin, size, simple } = this

    return html`
      ${(cards || []).map((c) => html`
        <playing-card
          suit=${c.suit}
          rank=${c.rank}
          size=${size}
          ?discarded=${c.discarded}
          ?blank=${c.blank}
          ?simple=${simple}
          ?hole=${hole}
          ?folded=${folded}
          ?no-win=${noWin}
          ?four-color=${fourColor}
          data-turbo-cache="false"
        ></playing-card>`
      )}
    `
  }
}
