import CardModel from 'replayer/models/Card'
import gameTypes, { isDrawGame } from './gameTypes'

export default (gameType) => {
  const v = gameTypes[gameType]
  let cards = []
  for (var i = 0; i < v; i++) {
    cards.push(CardModel.random({ discarded: isDrawGame(gameType) && (i == 3 || i == 1) }))
  }

  return cards
}
