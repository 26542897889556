import { LitElement, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import text from '../../text.js.erb'

import 'replayer/stylesheets/gameTypeLabel.scss'

@customElement('game-type-label')
export class GameTypeLabel extends LitElement {
  @property({ type: Boolean })
  ribbon = false

  @property({ type: String, attribute: 'game-slug' })
  gameSlug = null

  createRenderRoot() {
    return this
  }

  render() {
    return html`
      <label class="ui label ${this.ribbon ? 'ribbon' : ''} game-type game-type-${this.gameSlug}" data-turbo-cache="false">
        ${text.gameTypes[this.gameSlug]}
      </label>
    `
  }
}
