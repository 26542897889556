import { html } from 'lit-html'
import { fromJS } from 'immutable'

import Hand from 'replayer/models/Hand'
import './hand-replayer'

import fixtures from 'replayer/test/fixtures/hands'


export default {
  title: 'Components/hand-replayer',
}

export const LoadFromHand = ({ data, ...args }) => {
  let hand = null
  if (args.hand) {
    const [type, file] = args.hand.split('/')
    hand = new Hand(fromJS(fixtures[type][file]))
  }

  return html`
    <hand-replayer .hand=${hand}>
    </hand-replayer>
  `
}

LoadFromHand.argTypes = {
  hand: {
    control: 'select',
    options: Object.keys(fixtures).flatMap((type) => (
      Object.keys(fixtures[type]).map((file) => `${type}/${file}`)
    ))
  }
}

export const LoadFromScript = () => {
  const hand = fixtures['holdem']['full-ring-tournament-with-sitting-out-player.json']

  return html`
    <hand-replayer guid=${hand.guid}>
      <script type="application/json">
        ${JSON.stringify(hand)}
      </script>
    </hand-replayer>
  `
}
