import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    autoClose: Boolean,
    autoCloseDelay: { type: Number, default: 3000 },
    open: Boolean,
    target: String
  }

  openValueChanged() {
    const open = this.openValue
    const { classList } = this.element
    let property, value = ''
    if (classList.contains('top')) {
      property = 'margin-top'
      value = `${open ? this.element.offsetHeight : 0}px`
    }

    if (property && value !== '') {
      this.pusherTarget.style[property] = value
    }

    classList.toggle('visible', open)

    if (this.autoCloseValue) {
      setTimeout((() => { this.openValue = false }), this.autoCloseDelayValue)
    }
  }

  get pusherTarget() {
    return document.querySelector(this.targetValue)
  }
}
