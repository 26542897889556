import Fetch from 'fetch-rails'

/* eslint-disable import/prefer-default-export */
export const fetchTurboStream = (url, { method = null, body = null } = {}) => {
  const options = {}
  if (method !== null) options.method = method
  if (body !== null) options.body = body

  Fetch.html(url, options, { headers: { Accept: 'text/vnd.turbo-stream.html' } })
    .then((r) => r.text())
    .then((html) => Turbo.renderStreamMessage(html))
};
/* eslint-enable import/prefer-default-export */
