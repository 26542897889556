import Immutable from 'immutable'
import AllInDetector from './handActions/AllInDetector'
import PlayerActions from './handActions/PlayerActions'
import PotActions from './handActions/PotActions'
import CommunityDealActions from './handActions/CommunityDealActions'
import _ from 'lodash'
import Card from './Card'

const HandRecord = Immutable.Record({
  id: null,
  key: null,
  guid: null,
  handId: null,
  gameType: null,
  gameClass: null,
  gameSlug: null,
  gameLabel: null,
  playedAt: null,
  cap: false,
  limit: null,
  sb: null,
  bb: null,
  ante: null,
  currency: null,
  tournament: false,
  tournamentInfo: null,
  tournamentCurrency: null,
  seats: null,
  players: [],
  heroCards: [],
  button: null,
  streets: {},
  results: {rake: null, seats: {}},
  status: '',
  playMoney: false,
  player: null,
  createdAt: null,
  parseError: null,
  isPrivate: false,
  heroProfit: 0,
  buttonBlind: null,
  loaded: false,
  showKnownHoleCards: false,
})

const emptySeat = Immutable.fromJS({empty: true})

class Hand extends HandRecord {
  static get relationships() {
    return {
      player: 'belongsTo'
    }
  }

  get relationships() { return Hand.relationships }

  get isLimit() {
    if(this.gameType.match(/^(Limit|Razz|LHE|Badugi)/)) return true
    return false
  }

  get sbSeat() {
    return this.playerFromButtonSeatNumber(1)
  }

  get bbSeat() {
    return this.playerFromButtonSeatNumber(2)
  }

  get heroCardsModels() {
    let cards = this.heroCards || []
    if(cards && typeof cards.toJS == 'function') {
      cards = cards.toJS()
    }
    return cards.map((c) => new Card(c))
  }

  get createdTime() {
    if(!this._createdTime) {
      this._createdTime = new Date(this.createdAt).getTime()
    }
    return this._createdTime
  }

  get isDrawGame() {
    return ['badugi', 'deuce-to-seven', 'five-card-draw'].includes(this.gameClass)
  }

  playerFromButton(distance) {
    if(this.button) {
      const buttonIx = this.players.findIndex((v) => v.get('seat') == this.button)
      if(this.players.size == 2) {
        distance -= 1 // adjust for HU hands
      }
      let targetIx = buttonIx + distance
      if(targetIx >= this.players.size) {
        targetIx = targetIx - this.players.size
      }
      let player = this.players.get(targetIx)
      if(player && player.get('satOut') && !this.tournament) {
        return this.playerFromButton(distance + 1)
      }
      return player
    }
  }

  playerFromButtonSeatNumber(distance) {
    if(this.button) {
      return this.playerFromButton(distance).get('seat')
    }
  }

  firstAction() {
    this.currentStreet = null
    return this.nextAction()
  }

  nextAction() {
    this._setNextStreetAndActionIndex()
    return this.curAction()
  }

  prevAction() {
    this._setNextStreetAndActionIndex(-1)
    return this.curAction()
  }

  curAction() {
    // @TODO make ante fast forward work the same
    this._fastForwardInitialDeal()

    // do seats first as it can advance index (e.g. for ante posting)
    const seats  = this._seatsForAction()
    const community = this._communityForAction()
    const pot = this._potForAction()
    return {
      street: this.currentStreet,
      index: this.currentIndex,
      community,
      seats,
      pot,
      start: this._isStart(),
      end: this._isEnd()
    }
  }

  streetAction(street) {
    this.currentStreet = street
    this.currentIndex = 0
    return this.curAction()
  }

  moveToStreet(direction) {
    let streets = this.streetKeys
    const targetIx = streets.indexOf(this.currentStreet) + direction
    if(targetIx <= 0) {
      // totally reset
      this.currentStreet = streets[0]
      this.currentIndex = -1
    } else if(targetIx < streets.length) {
      return this.streetAction(streets[targetIx])
    }
    return this.curAction()
  }

  playerForSeat(seat) {
    return this.players.find((k,v) => k.get('seat') == seat)
  }

  /*
   * Returns the all the collections ordered by the pot & player
   * order won from (side pots > main pot) as an array
   */
  allCollections() {
    if(this._allCollections) {
      return this._allCollections
    }

    let collections = {}
    this.results.get('seats').forEach((seat, seatNumber) => {
      const collected = seat.get('collected')
      if(collected) {
        collected.forEach((entry,ix) => {
          let pot, won
          [pot,won] = _.toPairs(entry.toJS())[0]
          collections[pot] = collections[pot] || []
          collections[pot].push({seatNumber, won})
        })
      }
    })

    let result = []
    _.forOwn(collections, (theseCollections, pot) => {
      theseCollections.forEach((collected) => {
        const {seatNumber,won} = collected
        result.push({seatNumber: parseInt(seatNumber), won, from: pot})
      })
    })

    this._allCollections = result
    return result
  }

  _isStart() {
    return this.currentStreet == this._firstStreet && this.currentIndex == 0
  }

  get showCardsFrom() {
    if (this.isDrawGame) {
      // ignore draw games we don't know the hole cards until showdown
      return null
    }

    if (this.showKnownHoleCards) {
      return this.streets.keySeq().first()
    }

    if (typeof this._showCardsFrom === 'undefined') {
      this._showCardsFrom = new AllInDetector(this).allInStreet
    }
    return this._showCardsFrom
  }

  get streetKeys() {
    let streetKeys = this.streets.keySeq().toJS()
    streetKeys.push('showdown')
    return streetKeys
  }

  get _firstStreet() {
    return this.streets.keySeq().first()
  }

  get _firstStreetActions() {
    return this.streets.getIn([this._firstStreet, 'actions'])
  }

  get _firstDealIndex() {
    if(typeof this.__firstDealIndex == 'undefined') {
      this.__firstDealIndex = this._firstStreetActions
        .findIndex((v, ix) => {
          return v.get('action') == 'dealt'
        })
    }
    return this.__firstDealIndex
  }

  get _lastInitialDealIndex() {
    if(typeof this.__flastInitialealIndex == 'undefined') {
      this.__lastInitialDealIndex = this._firstStreetActions
        .findLastIndex((v, ix) => {
          return ix > this._firstDealIndex && v.get('action') == 'dealt'
        })
    }
    return this.__lastInitialDealIndex
  }

  _isEnd() {
    return this.currentStreet == 'showdown' && this.currentIndex == this._maxActionIndexForStreet('showdown')
  }

  /*
   * Expands the player actions into a history snap shot for them for each step
   */
  _playerActions(player) {
    if(!this.__playerActions) {
      this.__playerActions = {}
      this.players.forEach((p) => {
        let playerActions = new PlayerActions(p, this)
        this.__playerActions[p.get('seat')] = playerActions
      })
    }
    if(player) {
      return this.__playerActions[player.get('seat')]
    } else {
      return this.__playerActions
    }
  }

  /*
   * Expands the pot actions into a history snap shot for each step
   */
  get _potActions() {
    if(!this.__potActions) {
      this.__potActions = new PotActions(this)
    }
    return this.__potActions
  }

  get _communityDealActions() {
    if(!this.__communityDealActions) {
      this.__communityDealActions = new CommunityDealActions(this)
    }
    return this.__communityDealActions
  }

  get willRunTwice() {
    return this._communityDealActions.stateAt('showdown').second.length !== 0
  }

  _maxActionIndexForStreet(street) {
    if(street == 'showdown') {
      return this._potActions.streetState('showdown').keySeq().last()
    }
    let size = this.streets.getIn([street, 'actions']).size
    // for community deal streets they are offset by + 1
    if(['flop', 'turn', 'river'].indexOf(street) != -1) {
      size += 1
    }
    return size
  }

  _setNextStreetAndActionIndex(direction = 1) {
    let nextStreet
    let nextIndex
    let streetKeys = this.streetKeys

    if(!this.currentStreet) {
      nextStreet = streetKeys[0]
      nextIndex = -1
    } else {
      nextStreet = this.currentStreet
      nextIndex  = this.currentIndex + direction
      const atShowdown = this.currentStreet == 'showdown'
      if(!atShowdown &&
        nextIndex >= this._maxActionIndexForStreet(this.currentStreet)
      ) {
        nextStreet = streetKeys[streetKeys.indexOf(nextStreet) + 1]
        nextIndex  = 0
      } else if(nextIndex < 0) {
        const prevKeys = streetKeys.reverse()
        nextStreet = prevKeys[prevKeys.indexOf(nextStreet) + 1]
        nextIndex  = this._maxActionIndexForStreet(nextStreet) - 1
        nextIndex  = Math.max(nextIndex, 0)
      }
    }
    this.currentStreet = nextStreet
    this.currentIndex  = nextIndex
  }

  _playerState(seat) {
    const player = this.playerForSeat(seat)
    if(player) {
      let playerActions = this._playerActions(player)
      let state = playerActions.stateAt(this.currentStreet, this.currentIndex)
      state.name = player.get('name')
      if(player.get('hero')) { state.hero = player.get('hero') }
      if(player.get('satOut')) { state.satOut = true }
      state.chipsOut = this._potActions.chipsOutFor(seat, this.currentStreet, this.currentIndex)
      delete state.seat
      return state
    } else {
      return emptySeat.toJS()
    }
  }

  _seatsForAction() {
    let seats = []
    for(let seat = 1; seat <= this.seats; seat++) {
      seats.push(this._playerState(seat))
    }
    this._fastForwardAntePosts(seats)
    return seats
  }

  _fastForwardAntePosts(seats) {
    const firstSeat = _.find(seats, (s) => s.empty != true)
    if(firstSeat.action == 'post' && firstSeat.value == 'ante') {
      this.players.forEach((p,ix) => {
        if(ix != 0) {
          this.currentIndex += 1
          seats[p.get('seat') - 1] = this._playerState(p.get('seat'))
        }
      })
    }
  }

  _fastForwardInitialDeal() {
    if(this._isFirstInitialDealAction()) {
      this.currentIndex = this._lastInitialDealIndex
    }
  }

  _communityForAction() {
    return this._communityDealActions.stateAt(this.currentStreet, this.currentIndex)
  }

  _potForAction() {
    return this._potActions.stateAt(this.currentStreet, this.currentIndex)
  }

  _isFirstInitialDealAction() {
    const isFirstStreet = this.currentStreet == this._firstStreet
    const isFirstDeal   = this.currentIndex == this._firstDealIndex
    return isFirstStreet && isFirstDeal
  }
}

export default Hand
