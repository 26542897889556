import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon']

  connect() {
    if (!document.fullscreenEnabled) {
      this.element.style.display = 'none'
    }
  }

  set fullscreen(value) {
    if (value) {
      document.querySelector('[role="main"]').requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }

  get fullscreen() {
    return this._fullscreen || false
  }

  changed(e) {
    const fullscreen = document.fullscreenElement
    this._fullscreen = !!fullscreen
    this.iconTarget.classList.toggle('expand', !fullscreen)
    this.iconTarget.classList.toggle('compress', fullscreen)
  }

  toggle() {
    this.fullscreen = !this.fullscreen
  }
}
