/*
 * Detects the street from at which point a hand is considered all in
 * and the point we can turn over all cards
 */
export default class {
  constructor(hand) {
    // ignore draw games we don't know the hole cards until showdown
    if (hand.isDrawGame) {
      return null
    }

    let hasAllIn = false
    this._allInStreet = null

    hand.streets.some((street, streetName) => {
      const actions = street.get('actions')
      if (hasAllIn && actions.size === 0) {
        this._allInStreet = streetName
        return true
      }

      actions.forEach((action) => {
        if (action.get('allIn')) {
          hasAllIn = true
        }
      })
    })
  }

  get allInStreet() {
    return this._allInStreet
  }
}
