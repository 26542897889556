import {getWagerForAction} from './utils'
import Card from '../Card'
import _ from 'lodash'

/*
 * @TODO change get information from pot actions
 */

/*
 * Processes a single street of actions for the given seat
 */
export default class {
  constructor(sourceActions, playerActions, curStack, curDealt, hasFolded, cashedOut, offsetBy = 0) {
    // console.log(playerActions.seat, curStack)
    this.sourceActions = sourceActions
    this.playerActions = playerActions
    this.curStack      = curStack
    this.curDealt      = curDealt
    this.hasFolded     = hasFolded
    this.totalBet      = 0
    this.cashedOut     = cashedOut
    this.offsetBy      = offsetBy
    this._actions      = {}
  }

  get actions() {
    this._expandActions()
    return this._actions
  }

  _expandActions() {
    this.sourceActions.forEach((action, ix) => {
      if(action.get('seat') === this.playerActions.seat) {
        let thisAction = action.toJS()
        this._actions[ix + this.offsetBy] = thisAction
        this._addWagerForAction(thisAction)
        this._addCardsForAction(thisAction)
        this._addCashOutForAction(thisAction)
      }
    })
    // console.log(this._actions)
  }

  _addWagerForAction(action) {
    const val = action.value
    let wagered = getWagerForAction(
      action,
      this.totalBet,
      this.playerActions.sb,
      this.playerActions.bb,
      this.playerActions.ante,
      this.playerActions.buttonBlind
    )
    let removeValue = false
    let keepValueOnAction = false

    switch(action.action) {
      case 'post':
        keepValueOnAction = true
        break
      case 'bet':
      case 'raise':
      case 'call':
      case 'bring_in':
        removeValue = true
        break
    }

    // console.log('before', this.curStack, wagered)
    const wasAntePost = action.action == 'post' && val == 'ante'
    if(!wasAntePost) {
      // don't include these as they get moved straight to the pot after posting
      this.totalBet += wagered
    }

    this.curStack -= wagered

    // console.log('after', this.curStack, wagered)
    action.currentStack = this.curStack
    action.totalBet = 0 + this.totalBet
    if(keepValueOnAction) {
      action.value = val
    }

    if(wagered > 0) {
      action.amount = wagered
      if(removeValue) {
        delete action.value
      }
    }
  }

  _addCardsForAction(action) {
    let deal = {folded: this.hasFolded, cards: this.curDealt.slice()}
    if(action.action == 'dealt') {
      let newCards = []
      action.value.forEach((c) => {
        newCards.push(new Card({rank: c.rank, suit: c.suit}))
      })
      // replace any discarded cards with the new deal
      deal.cards.forEach((c,ix) => {
        if(c.discarded) {
          deal.cards[ix] = newCards.shift()
        }
      })
      // pop remainder on end
      newCards.forEach(c => deal.cards.push(c))
      delete action.value
    } else if(action.action == 'fold' || this.hasFolded) {
      this.hasFolded = true
      action.folded  = true
    } else if(action.action == 'discard') {
      if(deal.cards[0].unknown) {
        // we're dealing with unknown hole cards here, just remove the first x
        deal.cards.forEach((c, ix) => {
          if(ix < action.value.length) {
            deal.cards[ix] = c.set('discarded', true)
          }
        })
      } else {
        // discard specific cards
        const discarded = action.value.map((c) => new Card(c).toString())
        deal.cards.forEach((c, ix) => {
          if(_.intersection([c.toString()], discarded).length) {
            deal.cards[ix] = c.set('discarded', true)
          }
        })
      }
      action.value = action.value.length
    }
    this.curDealt = deal.cards
    action.cards = deal.cards
  }

  _addCashOutForAction(action) {
    if(action.action == 'cash_out') {
      this.cashedOut = true
      action.cashedOut = true
    }
  }
}
