import { Controller } from '@hotwired/stimulus'
import { useResize } from 'stimulus-use'
import Fetch from 'fetch-rails'
import { fetchTurboStream } from '../lib/turbo'

export default class extends Controller {
  static targets = ['currentIndex', 'entry', 'entryList', 'progress', 'replayer']

  static values = {
    index: { type: Number, default: 0 },
    status: String
  }

  connect() {
    this.processingCheck()

    if (this.statusValue !== 'parsed') { return }

    useResize(this, { element: this.replayerTarget })
  }

  processingCheck = () => {
    if (this.isProcessing(this.statusValue)) {
      setTimeout(this.checkStatus, 1000)
    }
  }

  checkStatus = () => {
    Fetch.json(window.location.href, {}, {})
      .then(({ status, progress }) => {
        if (this.isProcessing(status)) {
          if (this.hasProgressTarget) {
            this.progressTarget.style.setProperty("--progress", `${progress}%`);
          }
          this.processingCheck()
        } else {
          window.Turbo.visit(window.location.href)
        }
      })
  }

  isProcessing(status) {
    return status === 'processing' || status === 'queued'
  }

  resize({ height }) {
    this.entryListTarget.style.height = `${height}px`
  }

  indexValueChanged() {
    if (this.statusValue !== 'parsed') { return }

    const index = this.indexValue
    this.entryTargets.forEach((entry, ix) => entry.classList.toggle('active', ix === index))
    this.currentIndexTarget.innerText = index + 1

    const entry = this.entryTargets[index]

    entry.scrollIntoView({ behavior: 'smooth' })

    this.replayerTarget.setAttribute('guid', entry.dataset.guid)

    fetchTurboStream(entry.href)
  }

  //
  // Actions
  //
  loadHand(e) {
    e.preventDefault()
    this.indexValue = e.currentTarget.dataset.index
  }

  prevHand(e) {
    this.indexValue = Math.max(this.indexValue - 1, 0)
  }

  nextHand(e) {
    this.indexValue = Math.max(this.indexValue + 1, this.entryListTargets.length - 1)
  }
}
