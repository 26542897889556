export default {
  'badugi': 4,
  'courchevel': 5,
  'deuce-to-seven': 5,
  'five-card-draw': 5,
  'five-card-omaha': 5,
  'four-card-omaha': 4,
  'holdem': 2,
  'seven-card-stud': 7
}

export const isDrawGame = (gameType) => {
  return ['five-card-draw', 'deuce-to-seven', 'badugi'].indexOf(gameType) != -1
}

export const hasCommunity = (gameType) => {
  return ['courchevel', 'holdem', 'five-card-omaha', 'four-card-omaha'].indexOf(gameType) != -1
}
