import { Controller } from '@hotwired/stimulus'
import { useHover } from 'stimulus-use'
import { computePosition, flip, shift } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['popup', 'trigger']

  connect() {
    useHover(this, { element: this.triggerTarget })
  }

  mouseEnter() {
    this.popupTarget.classList.add('visible')
    computePosition(this.triggerTarget, this.popupTarget, {
      middleware: [flip(), shift()],
      placement: 'top-start',
    }).then(({ placement, x, y }) => {
      const top = placement.match('top')
      const left = placement.match('start')
      this.popupTarget.classList.toggle('top', top)
      this.popupTarget.classList.toggle('bottom', !top)
      this.popupTarget.classList.toggle('left', left)
      this.popupTarget.classList.toggle('right', !left)
      Object.assign(this.popupTarget.style, {
        left: `${x}px`,
        top: `${y - (top ? 8 : 0)}px`,
      })
    })
  }

  mouseLeave() {
    this.popupTarget.classList.remove('visible')
  }
}
