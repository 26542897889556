import { Controller } from '@hotwired/stimulus'
import text from '../../text.js.erb'

const NON_POKERSTARS_HAND = 'notPsHand'
const INCOMPLETE_HAND = 'incompleteHistory'

export default class extends Controller {
  static targets = ['errorWrapper', 'errorHeader', 'errorMessage', 'input', 'submit']

  static values = { multiple: Boolean }

  connect() {
    this.validateSource()
  }

  set errorKey(key) {
    if (key !== '') {
      const { title, messageHtml } = text.handUpload.errors[key]
      this.errorHeaderTarget.innerText = title
      this.errorMessageTarget.innerHTML = messageHtml
    }

    this.errorWrapperTarget.classList.toggle('visible', key !== '')
  }

  set disabled(disabled) {
    this.submitTarget.disabled = disabled
    this.submitTarget.classList.toggle('disabled', disabled)
  }

  validateSource() {
    const source = this.inputTarget.value
    const ident = 'PokerStars.*Hand #[0-9]+'
    let errorKey = ''
    let multiple = false
    const length = source.trim().length
    if (length) {
      if (!source.split(/\n\r/)[0].match(new RegExp(ident))) {
        errorKey = NON_POKERSTARS_HAND
      } else {
        const multipleHands = source.match(new RegExp(ident, 'g'))
        if (multipleHands && multipleHands.length > 1) {
          multiple = true
        } else if (!source.match(/\* SUMMARY \*/)) {
          errorKey = INCOMPLETE_HAND
        }
      }
    }

    this.errorKey = errorKey
    this.multipleValue = multiple
    this.disabled = errorKey !== '' || length === 0
  }

  multipleValueChanged() {
    this.submitTarget.innerText = `Upload hand${this.multipleValue ? 's' : ''}`
  }

  onChange(e) {
    this.validateSource()
  }

  onSubmit() {
    this.submitTarget.classList.add('loading')
  }
}
