const currencies = {
  '$': 'USD',
  '£': 'GBP',
  '€': 'EUR'
}

const numberFormat = (value, currency) => {
  const symbol = currencies[currency]
  const val    = symbol ? value / 100 : value

  let opts = { minimumFractionDigits: 0 }
  if(symbol) {
    opts = {
      style: 'currency',
      currency: symbol,
      minimumFractionDigits: 2,
      currencyDisplay: 'narrowSymbol'
    }

    // remove empty cents
    if (value % 100 == 0) {
      opts.minimumFractionDigits = 0
    }
  }

  let result

  if(window.Intl) {
    result = new Intl.NumberFormat(
      navigator.language || navigator.userLanguage || 'en-US', opts
    ).format(val)
  } else {
    // Mobile Safari < 10
    result = val.toLocaleString('en-US', opts)
  }
  return result
}

export default numberFormat
