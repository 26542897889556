import { html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import numberFormat from 'replayer/lib/numberFormat'

import './cashout-chip'
import './playing-cards'
import './poker-chips'

import 'replayer/stylesheets/seatPod.scss'

@customElement('seat-pod')
export class SeatPod extends LitElement {
  @property({ type: String })
  action = ''

  @property({ type: Boolean })
  button = false

  @property({ type: Number })
  bounty = 0

  @property({ type: String })
  bountyCurrency = ''

  @property({ type: Boolean })
  cap = false

  @property({ type: Array })
  cards = []

  @property({ type: Boolean, attribute: 'cashed-out' })
  cashedOut = false

  @property({ type: Number, attribute: 'chips-out' })
  chipsOut = 0

  @property({ type: Number, attribute: 'current-stack' })
  currentStack = 0

  @property({ type: String })
  currency = ''

  @property({ type: Boolean })
  empty = false

  @property({ type: String, attribute: 'game-class' })
  gameClass = ''

  @property({ type: Boolean })
  folded = false

  @property({ type: Boolean })
  hero = false

  @property({ type: String })
  name = ''

  @property({ type: Boolean, attribute: 'no-win' })
  noWin = false

  @property({ type: Boolean, attribute: 'sat-out' })
  satOut = false

  @property({ type: Number, attribute: 'seat-number' })
  seatNumber = 0

  @property({ type: Number })
  stack = 0

  @property({ type: String })
  align = 'left'

  @property({ type: Boolean, attribute: 'reverse-chips' })
  reverseChips = false

  createRenderRoot() {
    return this
  }

  get buttonHtml() {
    return this.button ? html`<div class="seat-pod__button"></div>` : null
  }

  get betContents() {
    if (this.chipsOut) {
      const layout = 'horizontal' + (this.reverseChips ? '-reverse' : '')
      return html`
        <div class="seat-pod__bet">
          <poker-chips
            value=${this.chipsOut}
            currency=${this.currency}
            layout=${layout}
            is-bet
          ></poker-chips>
        </div>
      `
    }
  }

  get displayAction() {
    if (this.action) {
      const [action, value] = this.action.toLowerCase().replace('_', ' ').split(':')

      if (['return uncalled'].includes(action)) {
        return
      }

      if (value || ['bet', 'bring in', 'call', 'complete', 'raise', 'fold', 'check', 'stand pat', 'discard', 'won'].includes(action)) {
        let label = action
        if (action == 'post') {
          label = `post ${value === 'button blind' ? 'blind' : value}`
        } else if(action == 'discard') {
          label = `draw ${value}`
        }
        return label
      }
    }
  }

  get stackLabel() {
    if (this.satOut) { return 'Sitting out' }

    if (this.empty) { return '' }

    if (this.cashedOut) { return 'Cash Out' }

    if (this.currentStack > 0 && !this.cap) {
      return numberFormat(this.currentStack, this.currency)
    } else {
      let label = ''
      if (this.currentStack === 0) {
        label = 'All in'
      } else if (this.cap) {
        label = 'CAP'
      }

      return label
    }
  }

  get bountyLabel() {
    if (this.bounty) {
      const formatted = numberFormat(this.bounty, this.bountyCurrency)
      return html`<div class="seat-chrome__bounty bounty-label" title="There is a bounty of ${formatted} on this player">
        <span class="bounty-label__content"><span>${formatted}</span></span>
      </div>`
    }
  }

  get cashOutSpinner() {
    return html `<div class="seat-chrome__cashout-chip" title="This player cashed out their hand" style=${styleMap({visibility: this.cashedOut ? 'visible' : 'hidden'})}>
      <cashout-chip currency=${this.currency}></cashout-chip>
    </div>`
  }

  render() {
    const actionText = this.displayAction
    return html`
      <div class=${classMap({ 'seat-pod__contents': true, '-with-action': actionText })}>
        <playing-cards
          class="seat-pod__hole-cards"
          .cards=${this.cards}
          game-class=${this.gameClass}
          ?folded=${this.folded}
          ?no-win=${this.noWin}
          size="large"
          hole
        >
        </playing-cards>
        <div class=${classMap({ 'seat-chrome__container': true, 'seat-chrome': true, '-with-action': actionText })}>
          <div class="seat-chrome__background">
            <div class="seat-chrome__text">
              <div class="seat-chrome__label seat-chrome__action">${actionText}</div>
              <div class="seat-chrome__label seat-chrome__name" title=${this.name}>${this.name}</div>
              <div class="seat-chrome__label seat-chrome__stack">${this.stackLabel}</div>
            </div>
          </div>
        </div>
        <div class="seat-chrome__container seat-chrome__overlays">
          ${this.cashOutSpinner}
          ${this.bountyLabel}

          ${this.buttonHtml}
          ${this.betContents}
        </div>
      </div>
    `
  }
}
