import { html } from 'lit-html'
import gameTypes from '../storiesSupport/gameTypes'
import generateCards from '../storiesSupport/generateCards'
import './playing-cards'

import CardModel from 'replayer/models/Card'

const genCards = (suit) => (
  [2, 3, 4, 5, 6, 7, 8, 9, 'T', 'J', 'Q', 'K', 'A', 'x'].map((rank) => (
    new CardModel({ rank, suit })
  ))
)

const allCards = {
  c: genCards('c'),
  d: genCards('d'),
  h: genCards('h'),
  s: genCards('s')
}

const outputCards = (size, { overlap = false, simple = false, fourColor = false } = {}) => (
  html`<div>
    <playing-cards .cards=${allCards.c} size=${size} ?simple=${simple} ?overlap=${overlap} ?four-color=${fourColor}></playing-cards>
    <playing-cards .cards=${allCards.d} size=${size} ?simple=${simple} ?overlap=${overlap} ?four-color=${fourColor}></playing-cards>
    <playing-cards .cards=${allCards.h} size=${size} ?simple=${simple} ?overlap=${overlap} ?four-color=${fourColor}></playing-cards>
    <playing-cards .cards=${allCards.s} size=${size} ?simple=${simple} ?overlap=${overlap} ?four-color=${fourColor}></playing-cards>
  </div>`
)

export default {
  title: 'Components/playing-cards',
}

export const Default = ({ data, ...args }) => (
  html`<div>
    ${outputCards(args.size, { overlap: false, simple: args.simple, fourColor: args.fourColor })}
    ${outputCards(args.size, { overlap: true, simple: args.simple, fourColor: args.fourColor })}
  </div>`
)

Default.argTypes = {
  size: { control: 'inline-radio', options: ['small', 'medium', 'large' ]}
}

Default.args = {
  simple: false,
  size: 'medium',
  fourColor: false,
}

/*------------------------------------------------------------------------------------------
 * States
 -----------------------------------------------------------------------------------------*/
// Discarding ------------------------------------------------------------------------------
export const Discarding = ({ data, ...args }) => {
  const discarding = ['2s', '3h', '4d', 'Kc', '7s'].map((c, ix) => {
    const discard = args.discard || []
    return new CardModel({ rank: c[0], suit: c[1], discarded: discard.includes(ix + 1) })
  })
  return html`<playing-cards .cards=${discarding} size=${args.size} hole></playing-cards>`
}

Discarding.argTypes = {
  discard: {
    control: { type: 'inline-check' },
    options: [1, 2, 3, 4, 5]
  }
}

Discarding.args = {
  discard: [2, 3, 4]
}

// Community ----------------------------------------------------------------------------------
export const Community = ({ data, ...args }) => {
  const communitySource = ['Ad', 'As', 'Ac', 'Ah', 'Ks']
  const { communityCount } = args
  const community = communitySource.slice(0, communityCount).map((c, ix) => {
    return new CardModel({ rank: c[0], suit: c[1] })
  })

  return html`<playing-cards .cards=${community} size=${args.size} community></playing-cards>`
}
Community.argTypes = {
  communityCount: {
    control: { type: 'number', max: 5, min: 0 }
  }
}

Community.args = {
  communityCount: 5,
}

// Game types ----------------------------------------------------------------------------------
export const GameTypes = ({ data, ...args }) => {
  const games = []

  Object.keys(gameTypes).forEach((k) => {
    let cards = generateCards(k)
    games.push(html`
      <div>
        <h1>${k}</h1>
        <playing-cards .cards=${cards} hole game-class=${k} ?folded=${args.folded} ?no-win=${args.noWin}></playing-cards>
      </div>
    `)
  })

  return html`${games.map((g) => g)}`
}

GameTypes.args = {
  folded: false,
  noWin: false
}

// Slotted ----------------------------------------------------------------------------------
export const Slotted = ({ data, ...args }) => {
  const communitySource = ['Ad', 'As', 'Ac', 'Ah', 'Ks']
  const { communityCount } = args
  const community = communitySource.slice(0, communityCount).map((c, ix) => {
    return new CardModel({ rank: c[0], suit: c[1] })
  })

  return html`
    <playing-cards community>
      ${community.map((c) => html`
        <playing-card rank=${c.rank} suit=${c.suit}></playing-card>
      `)}
    </playing-cards>`

}
