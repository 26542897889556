import PlayerStreetActionsProcessor from './PlayerStreetActionsProcessor'
import Card from '../Card'
import StateBasedActions from './StateBasedActions'

/*
 * Expands out the player actions into something easier to access
 *
 * E.g.
 * .streets = {
 *    deal: {
 *      1: { type: 'post', value: 'SB', currentStack: 100}
 *    },
 *    preflop: {
 *      5: { type: 'raise', value: 25, currentStack: 75}
 *      8: { type: 'raise', value: 100, currentStack: 0}
 *    },
 *    turn: {},
 *    river: {},
 *    result: { type: 'won', value: 250 }
 * }
 *
 * We can then do:
 *  `playerActions.getState('preflop', x)`
 *
 * to get the state of this player at the given action index
 */
export default class extends StateBasedActions {
  constructor(player, hand) {
    super(hand)
    // console.log('-----')
    this.player = player
    this.seat = player.get('seat')
    this.startingStack = player.get('stack')
    this.bounty = player.get('bounty')
    this.streets = {}
    this._expandStreetActions()
  }

  stateAt(targetStreet, actionIndex) {
    const firstStateIndex = this._firstStateIndexFor(targetStreet, actionIndex)
    let {index, street, isPreviousState, isPreviousStreet} = firstStateIndex

    let showHoleCards = false
    const { showCardsFrom } = this.hand
    const streetKeys = Object.keys(this.streets)

    if (showCardsFrom && streetKeys.indexOf(targetStreet) >= streetKeys.indexOf(showCardsFrom)) {
      showHoleCards = true
    }

    let action = {currentStack: this.startingStack, cards: []}
    if(index != -1) {
      action = this.streets[street][index]
    }

    return this._cleanActionState(
      action,
      isPreviousState,
      isPreviousStreet,
      showHoleCards
    )
  }

  _expandStreetActions() {
    let curStack  = 0 + this.startingStack
    let curDealt  = []
    let hasFolded = false
    let cashedOut = false
    this.hand.streets.forEach((street, streetName) => {
      const actionOffset = ['flop', 'turn', 'river'].indexOf(streetName) != -1 ? 1 : 0

      const streetProcessor = new PlayerStreetActionsProcessor(
        street.get('actions'),
        this,
        curStack,
        curDealt,
        hasFolded,
        cashedOut,
        actionOffset
      )
      this.streets[streetName] = streetProcessor.actions
      curStack  = streetProcessor.curStack
      curDealt  = streetProcessor.curDealt
      hasFolded = streetProcessor.hasFolded
      cashedOut = streetProcessor.cashedOut

      if (curDealt.length) {
        this.dealtCards = curDealt
      }
    })
    this._expandShowDownActions(curStack, curDealt, cashedOut)
  }

  _expandShowDownActions(curStack, curDealt, cashedOut) {
    let actions = []
    const result = this.hand.results.get('seats').toJS()[this.seat]
    if(result) {
      let cards = []
      if(result.hand) {
        result.hand.forEach((c) => {
          cards.push(new Card({rank: c.rank, suit: c.suit}))
        })
      } else {
        cards = curDealt.slice()
      }

      this.showdownCards = [...cards]

      // showdown the cards
      let showdownBase = { currentStack: 0 + curStack, cards}
      if(!result.collected || result.collected.length == 0) {
        showdownBase.noWin = true
      }
      if (cashedOut) {
        showdownBase.cashedOut = true
      }

      actions.push(showdownBase)
      // align collections with the pot actions
      this.hand.allCollections().forEach((collection, ix) => {
        let action = Object.assign({}, showdownBase)
        if(collection.seatNumber == this.seat) {
          action.action = 'won'
          action.amount = collection.won
        }
        actions.push(action)
      })
    }
    this.streets['showdown'] = actions
  }

  _cleanActionState(actionState, isPreviousState = false, isPreviousStreet = false, showHoleCards = false) {
    let action = {
      currentStack: actionState.currentStack,
      cards: actionState.cards
    }
    if(typeof this.bounty != 'undefined') {
      action.bounty = this.bounty
    }
    let mergeKeys  = ['folded', 'action', 'amount', 'cashedOut', 'totalBet', 'totalWon', 'value', 'noWin']
    let removeKeys = []
    if(isPreviousState) {
      removeKeys.push('action', 'amount', 'value')
      if(isPreviousStreet) {
        removeKeys.push('totalBet')
      }
    }
    mergeKeys.forEach((k) => {
      if (actionState.hasOwnProperty(k)) { // eslint-disable-line no-prototype-builtins
        action[k] = actionState[k]
      }
    })
    removeKeys.forEach((k) => {
      delete action[k]
    })

    if (showHoleCards) {
      if (this.showdownCards && !this.showdownCards[0].unknown) {
        action.cards = this.showdownCards
      } else {
        action.cards = this.dealtCards
      }
    }

    return action
  }
}
