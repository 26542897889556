import { html } from 'lit-html'

import './cashout-chip'

export default {
  title: 'Components/cashout-chip',
  argTypes: {
    currency: { control: 'inline-radio', options: ['', '$', '£', '€'] },
  },
  args: {
    currency: '$',
  }
}

const Template = ({ currency }) => {
  return html`<cashout-chip currency=${currency}></cashout-chip>`
}

export const Default = Template.bind({})
