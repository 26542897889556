import { html } from 'lit-html'

import './poker-chips'

export default {
  title: 'Components/poker-chips'
}

export const Stacks = ({ data, ...args }) => (
  html`<div style={{ padding: '10px' }}>
    <div>
      <h4>Adjustable - Bet size: ${args.adjustableValue}</h4>
      <h5>Regular</h5>
      <poker-chips value=${args.adjustableValue} currency=${args.currency} layout=${args.layout} ?is-bet=${args.isBet}></poker-chips>
      <h5>Small</h5>
      <poker-chips value=${args.adjustableValue} size="small" currency=${args.currency} layout=${args.layout} ?is-bet=${args.isBet}></poker-chips>
      <hr />
    </div>
  </div>`
)

Stacks.argTypes = {
  currency: { control: 'select', options: ['', '$'] },
  layout: { control: 'inline-radio', options: ['vertical', 'horizontal', 'horizontal-reverse'] }
}

Stacks.args = {
  adjustableValue: 1200,
  currency: '$',
  layout: 'vertical',
  isBet: false
}

const outputStacks = (stacks, currency) => (
  html`<div style={{ padding: '10px' }}>
    ${stacks.map((v) => html`
      <div>
        <h4>Bet size: ${v}</h4>
        <poker-chips value=${v} currency=${currency}></poker-chips>
        <hr />
      </div>
    `)}
  </div>`
)

export const StacksCash = () => (
  outputStacks([
    56745621556,
    1256034500,
    7104400,
    5715700,
    1210700,
    25000,
    25700,
    12656,
    50
  ], '$')
)

export const StacksTournament = () => (
  outputStacks([
    745621556,
    56034500,
    7104400,
    5715700,
    210700,
    25000,
    25700,
    12656,
    50
  ], '')
)
