import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['popup', 'source']

  static types = {
    facebook: (scope) => ( `https://www.facebook.com/sharer/sharer.php?u=${scope.url}` ),
    twitter: (scope) => (`https://twitter.com/share?url=${scope.url}&text=${scope.title}. ${scope.description}&hashtags=${scope.hashtags}` )
  }

  get url() {
    return this.data.get('url') || window.location.href
  }

  get title() {
    return this.data.get('title')
  }

  get description() {
    return this.data.get('description')
  }

  get hashtags() {
    return this.data.get('hashtags')
  }

  set copied(copied) {
    this.popupTarget.innerText = copied ? this.data.get('copiedLabel') : this.data.get('label')
    this.popupTarget.classList.toggle('copied', copied)
  }

  copyToClipboard() {
    const input = document.createElement('input')
    input.value = this.hasSourceTarget ? this.sourceTarget.innerHTML.trim() : this.url
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    input.remove()

    if (this.hasPopupTarget) {
      clearTimeout(this.resetCopyTimer)
      this.copied = true
      this.resetCopyTimer = setTimeout(() => { this.copied = false }, 1250)
    }
  }

  openShareWindow() {
    let url = this.constructor.types[this.data.get('type')]
    if (url) {
      url = url(this)
      const width = 550
      const height = 400

      const config = {
        height,
        width,
        location: 'no',
        toolbar: 'no',
        status: 'no',
        directories: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'no',
        centerscreen: 'yes',
        chrome: 'yes',
        left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
        top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2,
      }

      window.open(
        url,
        '',
        Object.keys(config)
          .map(key => `${key}=${config[key]}`)
          .join(', '),
      )
    }
  }

  share() {
    if (this.data.get('type') == 'clipboard') {
      this.copyToClipboard()
    } else {
      this.openShareWindow()
    }
  }
}
