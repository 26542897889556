import EventEmitter from 'events'

export const defaultSettings = {
  fourColorDeck: { type: Boolean, default: false },
  showKnownHoleCards: { type: Boolean, default: false }
}

export const settings = new EventEmitter()
settings.setMaxListeners(Infinity)

export const getSetting = (key) => {
  const setting = defaultSettings[key]
  const val = localStorage.getItem(`settings:${key}`) || setting.default
  if (setting.type === Boolean) {
    return val === 'true'
  }
  return val
}

export const saveSetting = (key, value) => {
  if (!defaultSettings[key]) {
    return
  }

  // @TODO enforce type
  localStorage.setItem(`settings:${key}`, value)
  settings.emit(`changed:${key}`, value)
}
