import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['deleteModal', 'form']

  static values = {
    loaded: Boolean,
    key: String,
    url: String
  }

  get token() {
    return window.localStorage.getItem(this.keyValue)
  }

  connect() {
    if (this.loadedValue !== true && this.token) {
      const href = `${this.urlValue}?token=${encodeURIComponent(this.token)}`
      fetch(href, {
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
        },
      })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  submitIfChanged(e) {
    if (e.target.value !== e.target.dataset.originalValue) {
      this.submit()
    }
  }

  openDeleteModal(e) {
    e.preventDefault()
    if (this.hasDeleteModalTarget) {
      this.deleteModalTarget.dataset.semanticModalOpenValue = true
    }
  }
}
