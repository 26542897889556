import { LitElement, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import './money-label'
import { PokerChip } from './poker-chip'

@customElement('poker-chips')
export class PokerChips extends LitElement {
  @property({ type: Number })
  value = 0

  @property({ type: String })
  currency = null

  createRenderRoot() {
    return this
  }

  get stacks() {
    let remainder = 0 + this.value
    // play money/tournament chips don't use smallest chip values (1c, 5c, 25c)
    if (!this.currency) {
      remainder *= 100
    }
    let chips = {}
    PokerChip.DENOMINATIONS.forEach((d) => {
      const result = remainder / d
      if (result >= 1) {
        const quantity = Math.floor(result)
        remainder -= d * quantity
        chips[d] = quantity
      }
    })
    return this.buildStacks(chips)
  }

  buildStacks(chips) {
    const ordered = Object.keys(chips).map(v => parseInt(v))
    let stacks = []
    let stackIndex = 0
    const maxStack = 14
    ordered.forEach((denomination) => {
      const type = PokerChip.CHIPS[denomination].type
      for (let ix = 0; ix < chips[denomination]; ix++) {
        let stack = stacks[stackIndex]
        if (
          !stack ||
          stack.type != type ||
          stack.chips.length > maxStack
        ) {
          stack = { type: type, chips: [] }
          stacks.push(stack)
          stackIndex = stacks.length - 1
        }
        stack.chips.push(denomination)
      }
    })

    stacks.forEach((stack) => {
      stack.chips = stack.chips.map((c, ix) => (
        html`<poker-chip value=${c}></poker-chip>`
      ))
    })

    return stacks.reverse()
  }

  render() {
    if(this.value == 0) { return null }

    return html`
      <div class="chips__stacks">
        ${this.stacks.map((s) => html`<div class="chips__stack">${s.chips}</div>`)}
      </div>
      <money-label value=${this.value} currency=${this.currency} class="chips__label"></money-label>
    `
  }
}
