import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    open: Boolean
  }

  connect() {
    this.element[this.identifier] = this
  }

  disconnect() {
    this.element[this.identifier] = null
  }

  openValueChanged() {
    if (this.openValue) {
      this.show()
    } else {
      this.close()
    }
  }

  show() {
    this.openValue = true
    const container = this.container
    if (this.existingModal) {
      this.existingModal.remove()
    }

    const modalHTML = this.element.content.firstElementChild.cloneNode(true)
    modalHTML.classList.add('visible', 'active')
    container.appendChild(modalHTML)
    container.classList.add('visible', 'active')
  }

  close() {
    this.openValue = false
    const container = this.container
    const modal = this.existingModal
    if (modal) {
      modal.remove()
    }
    container.classList.remove('visible', 'active')
  }

  get existingModal() {
    return this.container.querySelector(`#${this.modalId}`)
  }

  get modalId() {
    return this.element.content.firstElementChild.id
  }

  get container() {
    let el = document.querySelector('.ui.page.modals.dimmer')
    if (!el) {
      el = document.createElement('div')
      el.classList.add('ui', 'page', 'modals', 'dimmer')
      document.body.appendChild(el)
    }
    return el
  }
}
