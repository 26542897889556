import { html } from 'lit'
import text from '../../text.js.erb'

import './game-type-label'

const generateLabels = (asRibbon = false) => (
  html`<ul style="list-style-type: none;">
    ${Object.keys(text.gameTypes).map((slug) => (
      html`
        <li style="margin: 15px 0;">
          <game-type-label game-slug=${slug} ?ribbon=${asRibbon}></game-type-label>
        </li>
      `
    ))}
  </ul>`
)

export default {
  title: 'Components/game-type-label'
}

export const All = () => (
  html`<div>
    ${generateLabels()}
    ${generateLabels(true)}
  </div>`
)
