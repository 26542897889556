import { LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import 'replayer/stylesheets/chips.scss'

@customElement('poker-chip')
export class PokerChip extends LitElement {
  @property({ type: Number })
  value = 0

  createRenderRoot() {
    return this
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.setAttribute('class', `-value-${PokerChip.CHIPS[this.value].class}`)
    }
  }

  render() {
    const chip = PokerChip.CHIPS[this.value]

    return ''
  }
}

PokerChip.CHIPS = {
  500000000000: { type: 'chip', class: '5b' },
  100000000000: { type: 'chip', class: '1b' },
  50000000000: { type: 'chip', class: '500m' },
  10000000000: { type: 'chip', class: '100m' },
  2500000000: { type: 'chip', class: '25m' },
  500000000: { type: 'chip', class: '5m' },
  100000000: { type: 'chip', class: '1m' },
  50000000: { type: 'chip', class: '500k' },
  10000000: { type: 'chip', class: '100k' },
  2500000: { type: 'chip', class: '25k' },
  500000: { type: 'chip', class: '5k' },
  100000: { type: 'chip', class: '1k' },
  50000: { type: 'chip', class: '500' },
  10000: { type: 'chip', class: '100' },
  2500: { type: 'chip', class: '25' },
  500: { type: 'chip', class: '5' },
  100: { type: 'chip', class: '1' },
  25: { type: 'chip', class: '25c' },
  5: { type: 'chip', class: '5c' },
  1: { type: 'chip', class: '1c' }
}

PokerChip.DENOMINATIONS = Object.keys(PokerChip.CHIPS).map(v => parseInt(v)).sort((a, b) => a - b).reverse()
