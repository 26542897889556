import { LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import numberFormat from 'replayer/lib/numberFormat'

import 'replayer/stylesheets/moneyLabel.scss'

@customElement('money-label')
export class MoneyLabel extends LitElement {
  @property({ type: Number })
  value = 0

  @property({ type: String })
  currency = null

  @property({ type: String })
  prefix = ''

  createRenderRoot() {
    return this
  }

  render() {
    return this.prefix + numberFormat(this.value, this.currency)
  }
}
