const files = [
  'hi-hero-folded-river.json',
  'hi-lo-multiple-side-pots.json',
  'hi-lo-split-pot.json',
  'hi-with-side-pot.json'
]

const result = {}

files.forEach((k) => {
  result[k] = require(`./${k}`)
})

module.exports = result
