import { html } from 'lit-html'

import { PokerChip } from './poker-chip'

export default {
  title: 'Components/poker-chip',
  argTypes: {
    value: { control: 'select', options: Object.keys(PokerChip.CHIPS) },
  },
  args: {
    value: '1',
  }
}

export const Chip = ({ data, ...args }) => (
  html`<poker-chip value=${args.value}></poker-chip>`
)

export const All = ({ data, ...args }) => (
  html`<div style="display: flex; gap: 5px;">
    ${(Object.keys(PokerChip.CHIPS)).map(v => (
    html`<div style="text-align: center;">
        <strong>${PokerChip.CHIPS[v].class}</strong>
        <br />
        <poker-chip value=${v}></poker-chip>
      </div>`
  ))}
  </div>`
)

