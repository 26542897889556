import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  set checked(checked) {
    this.inputTarget.checked = checked
    this.element.classList.toggle('checked', checked)
  }

  get checked() {
    return this.inputTarget.checked
  }

  toggle() {
    this.checked = !this.checked
  }
}
