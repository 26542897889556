import { html } from 'lit-html'
import CardModel from 'replayer/models/Card'
import gameTypes from '../storiesSupport/gameTypes'
import generateCards from '../storiesSupport/generateCards'

import './seat-pod'

const ACTIONS = [
  'check',
  'raise',
  'bring_in',
  'stand_pat',
  'won',
  'call',
  'fold',
  'post:ante',
  'post:SB',
  'post:BB',
  'post:BUTTON_BLIND',
  'discard:2',
  'return_uncalled:420',
]

export default {
  title: 'Components/seat-pod',
  argTypes: {
    action: { control: 'select', options: ['', ...ACTIONS] },
    bountyCurrency: { control: 'inline-radio', options: ['', '$'] },
    currency: { control: 'inline-radio', options: ['', '$'] },
    gameClass: { control: 'select', options: Object.keys(gameTypes) },
    layout: { control: 'inline-radio', options: ['left', 'right'] },
    name: {
      control: { type: 'text' }
    },

  },
  args: {
    button: true,
    bounty: 100,
    bountyCurrency: '$',
    cards: [],
    cashedOut: false,
    cap: false,
    chipsOut: 12345,
    currency: '$',
    currentStack: 23456,
    empty: false,
    folded: false,
    gameClass: 'holdem',
    hero: true,
    layout: 'left',
    name: 'Foo Bar',
    noWin: false,
    satOut: false,
    seatNumber: 1
  }
}

const Template = ({ action, button, bounty, bountyCurrency, cap, cards, cashedOut, currency, currentStack, chipsOut, empty, folded, gameClass, hero, layout, name, noWin, satOut, seatNumber }) => {
  let displayCards = cards.map((text) => new CardModel({ rank: text[0], suit: text[1] }))
  if (displayCards.length === 0 && !(satOut || empty)) {
    displayCards = generateCards(gameClass)
  }

  return html`<seat-pod
    action=${empty ? '' : action }
    ?button=${button}
    bounty=${bounty}
    ?cap=${cap}
    .cards=${displayCards}
    ?cashed-out=${cashedOut}
    currency=${currency}
    bountyCurrency=${bountyCurrency}
    current-stack=${empty ? '' : currentStack}
    chips-out=${empty || satOut ? '' : chipsOut}
    ?empty=${empty}
    ?folded=${folded}
    game-class=${gameClass}
    ?hero=${hero}
    name=${empty ? '' : name}
    ?no-win=${noWin}
    ?sat-out=${satOut}
    seat-number=${seatNumber}
    layout=${layout}
  ></seat-pod>`
}

export const Default = Template.bind({})

/* ---------------------------------------------------------------------------
 * Game Types
 * ------------------------------------------------------------------------ */
export const GameTypes = ({ data, ...args }) => {
  let seats = []
  Object.keys(gameTypes).forEach((k) => {
    const seat = {
      ...args,
      cards: generateCards(k),
      gameClass: k
    }
    seats.push(html`
      <h3>${k}</h3>
      ${Template(seat)}
    `)
  })

  const studStreetSeat = {
    ...args,
    cards: generateCards('seven-card-stud').slice(0, args.studStreet),
    gameClass: 'seven-card-stud'
  }

  seats.push(html`
    <h3>seven-card-stud streets</h3>
    ${Template(studStreetSeat)}
  `)

  return html`${seats}`
}

GameTypes.argTypes = {
  gameClass: { control: false },
  studStreet: { control: 'inline-radio', options: [3,4,5,6,7] }
}

GameTypes.args = {
  studStreet: 3,
}

/* ---------------------------------------------------------------------------
 * Big Data
 * ------------------------------------------------------------------------ */
export const BigData = Template.bind({})

BigData.args = {
  bounty: 12345678910,
  chipsOut: 12345678910,
  currentStack: 2425000000,
  name: 'Percy McFadden likes to 3-bet you',
}

/* ---------------------------------------------------------------------------
 * States
 * ------------------------------------------------------------------------ */
export const States = ({ data, ...args }) => {
  let base = {
    cards: [],
    chipsOut: 16,
    name: 'Percy McGherkin',
    action: 'raise',
    currentStack: 12345,
    layout: 'right',
  }
  const actions = ACTIONS

  const { showAction, hasBounty, bounty } = args;

  if (hasBounty) {
    base.bounty = bounty
  }

  const seats = {
    'Empty': { ...base, empty: true, name: '', currentStack: null },
    'Unknown': { ...base, gameClass: 'holdem', cards: [CardModel.unknown(), CardModel.unknown()] },
    'Stud Unknown': { ...base, gameClass: 'seven-card-stud', cards: [CardModel.unknown(), CardModel.unknown(), CardModel.random()] },
    'Default': { ...base, gameClass: 'holdem' },
    'Hero': { ...base, hero: true },
    'Sat out': { ...base, satOut: true },
    'Capped bet': { ...base, cap: true },
    'With cards': { ...base, cards: [CardModel.random(), CardModel.random()] },
    'With cards - folded': { ...base, cards: [CardModel.random(), CardModel.random()], folded: true },
    'With cards - no win': { ...base, cards: [CardModel.random(), CardModel.random()], noWin: true },
    'No bet': { ...base, chipsOut: 0 },
    'Button': { ...base, button: true },
    'Not button': { ...base, button: false }
  }

  actions.map((action) => {
    const hasChips = ['raise', 'bring_in', 'won', 'call', 'post'].indexOf(action) != -1
    const amount = hasChips ? (action == 'post' ? 25 : 1234) : 0
    seats[`Action: ${action}`] = {
      ...base,
      action: showAction ? action : '',
      chipsOut: amount
    }
  })

  return html`
    <div style="display: flex; flex-wrap: wrap; gap: 1em;">
      ${Object.keys(seats).map((k) => html`
        <div style="border: 1px solid white; padding: 0.5em;">
          <h3>${k}</h3>
          ${Template(seats[k])}
        </div>`
      )}
    </div>
  `
}

States.argTypes = {
  button: { control: false },
  cards: { control: false },
  cap: { control: false },
  chipsOut: { control: false },
  currency: { control: false },
  currentStack: { control: false },
  empty: { control: false },
  folded: { control: false },
  gameClass: { control: false },
  hero: { control: false },
  name: { control: false },
  noWin: { control: false },
  satOut: { control: false },
  seatNumber: { control: false },
}

States.args = {
  showAction: true,
  hasBounty: true,
}

/* ---------------------------------------------------------------------------
 * Mobile
 * ------------------------------------------------------------------------ */
export const Mobile = GameTypes.bind({})
Mobile.parameters = {
  viewport: {
    defaultViewport: 'mobile2'
  }
}
