import StateBasedActions from './StateBasedActions'
import {isWagerAction, getWagerForAction} from './utils'
import Immutable from 'immutable'
import _ from 'lodash'

export default class extends StateBasedActions {
  constructor(hand, debug) {
    super(hand)
    this.debug = debug
    this.streets = Immutable.Map()
    this._expandPotActions()
  }

  streetState(street) {
    return this.streets.get(street)
  }

  stateAt(targetStreet, targetIndex) {
    const firstStateIndex = this._firstStateIndexFor(targetStreet, targetIndex)
    let {index, street} = firstStateIndex
    return this.streets.getIn([street, parseInt(index)])
  }

  chipsOutFor(seat, targetStreet, targetIndex) {
    const state = this.stateAt(targetStreet, targetIndex)
    if(state) {
      return state.getIn(['seats', `${seat}`, 'chipsOut']) || 0
    }
    return 0
  }

  _expandPotActions() {
    this._hasSidePots = this.hand.results.get('pots').size > 1
    this._currentPot  = 'main'
    this.hand.streets.forEach((street, streetName) => {
      if(streetName != 'spit') {
        this._resetLastParsedState()
      }
      let actions = Immutable.Map()
      const streetActions = street.get('actions')

      const offsetBy = ['flop', 'turn', 'river'].indexOf(streetName) != -1 ? 1 : 0

      // all streets get the 0 index pot actions (as this is when bets)
      // are transferred in display
      actions = actions.set(0, this._lastParsedState)

      streetActions.forEach((action, ix) => {
        const state = this._expandActionState(action, actions)
        if(state) {
          if(this.debug) {
            // console.log(streetName, ix)
          }
          actions = actions.set(ix + offsetBy, state)
        }
      })

      const nextStreet =  this.hand.streetKeys[this.hand.streetKeys.indexOf(streetName) + 1]
      if(actions.size != 0 && nextStreet != 'spit') {
        // don't move bets into display until after spit street in Courchevel
        this._moveBetsToDisplayPotsAtEndOfStreet(actions, streetName)
      }

      this.streets = this.streets.set(streetName, actions)
    })
    this._expandShowdown()
  }

  _expandShowdown() {
    this._resetLastParsedState()
    let actions = Immutable.Map()

    // move chips to main pot on showdown
    actions = actions.set(0, this._mergeState('main', 0, 'displayPot'))

    this.hand.allCollections().forEach((collection, ix) => {
      actions = actions.set(
        ix + 1,
        this._mergeState(
          collection.from,
          collection.won * -1,
          'displayPot',
          collection.seatNumber,
          collection.won
        )
      )
    })
    if(this.debug) {
      // console.log('showdown', actions)
    }
    this.streets = this.streets.set('showdown', actions)
  }

  _resetLastParsedState() {
    let totalPot, displayPots
    if(this._lastParsedState) {
      totalPot = this._lastParsedState.get('totalPot')
      displayPots = this._lastParsedState.get('displayPots')
    } else {
      totalPot = 0
      displayPots = Immutable.Map()
    }
    this._lastParsedState = Immutable.Map({
      seats: Immutable.Map(),
      totalPot,
      displayPots
    })
  }

  _expandActionState(action, actions) {
    if(isWagerAction(action)) {
      const seat  = action.get('seat')
      const lastSeatState = this._lastStateForSeat(seat)
      const thisWager = getWagerForAction(
        action.toJS(),
        lastSeatState.get('chipsOut'),
        this.hand.sb,
        this.hand.bb,
        this.hand.ante,
        this.hand.buttonBlind
      )

      if(this.debug) {
        // console.log(action, thisWager)
      }

      let includeInChipsOut = true
      let target = 'total'
      if(action.get('action') == 'post') {
        // antes and dead small blinds get moved straight to display pot
        if(
          (action.get('value') == 'ante') ||
          (action.get('value') == 'SB' && seat != this.hand.sbSeat)
        ) {
          includeInChipsOut = false
          target = 'both'
        }
      }

      this._mergeState(this._currentPot, thisWager, target, seat, includeInChipsOut ? thisWager : 0)
      if(action.get('allIn')) {
        this._lastParsedState = this._lastParsedState.setIn(['seats', `${seat}`, 'allIn'], true)
      }
      return this._lastParsedState
    }
  }

  _mergeState(potName, amount, target = 'total', seat = null, adjustChipsOutBy = 0) {
    const lastTotalPot   = this._lastParsedState.get('totalPot') || 0
    const lastDisplayPot = this._lastParsedState.getIn(['displayPots', potName]) || 0
    const addToTotal     = target == 'total' || target == 'both'
    const addToDisplay   = target == 'displayPot' || target == 'both'

    let toMerge = {
      totalPot: lastTotalPot + (addToTotal ? amount : 0),
      displayPots: {[potName]: lastDisplayPot + (addToDisplay ? amount : 0)},
      seats: {}
    }
    if(seat && adjustChipsOutBy != 0) {
      const lastSeatState = this._lastStateForSeat(seat)
      toMerge.seats[seat] = lastSeatState.toJS()
      toMerge.seats[seat].chipsOut += adjustChipsOutBy
    }

    this._lastParsedState = this._lastParsedState.mergeDeep(toMerge)
    return this._lastParsedState
  }

  _lastStateForSeat(seat) {
    const seatStr = `${seat}`
    let state = this._lastParsedState.getIn(['seats', seatStr])
    if(!state) {
      state = Immutable.Map({chipsOut: 0})
      this._lastParsedState = this._lastParsedState.setIn(['seats', seatStr], state)
    }
    return state
  }

  _moveBetsToDisplayPotsAtEndOfStreet(actions, streetName) {
    const lastAction = actions.last()
    const seats      = lastAction.get('seats')
    const hasAllIn   = seats.find((seat, seatNum) => seat.get('allIn'))
    let displayPots  = lastAction.get('displayPots')
    if(this.debug) {
      // console.log(`moving bets to display pots at end of ${streetName} - hasAllIn: ${hasAllIn}`)
    }
    if(this._hasSidePots && hasAllIn) {
      displayPots = this._createSidePotsAtEndOfStreet(seats, displayPots)
    } else {
      displayPots = this._moveBetsToMainPot(seats, displayPots)
    }
    if(displayPots) {
      this._lastParsedState = this._lastParsedState.set('displayPots', displayPots)
    }
  }

  _moveBetsToMainPot(seats, displayPots) {
    // no possible side pots move everything into main pot
    const totalChips = seats.reduce((v, seat, seatNum) => {
      return v + seat.get('chipsOut')
    }, 0)
    return this._increamentDisplayPots(displayPots, totalChips)
  }

  _sortedAllInChipsOutForSeats(seats) {
    return _.uniq(
      seats
        .filter(seat => seat.get('allIn'))
        .map(seat => seat.get('chipsOut'))
        .valueSeq()
        .toJS()
    ).sort((a,b) => a - b)
  }

  _createSidePotsAtEndOfStreet(seats, displayPots) {
    if(this.debug) {
      // console.log('>> creating side pots')
    }
    const allIns = this._sortedAllInChipsOutForSeats(seats)
    let potMaxContributions = allIns.map((v,ix) => {
      const targIx = ix - 1
      return targIx == -1 ? v : v - allIns[targIx]
    })

    // make room for any extra unclosed pots (either bets going
    // into it or the next empty side pot)
    potMaxContributions.push(Infinity)

    let potContributions = []
    seats.forEach((seat) => {
      // figure out how much this player contributed to each of the pots
      let contributed = seat.get('chipsOut')
      potMaxContributions.forEach((maxContribution, index) => {
        let remainder = Math.max(0, contributed - maxContribution)
        potContributions[index] = potContributions[index] || 0
        potContributions[index] += contributed - remainder
        contributed = remainder
      })
    })

    if(this.debug) {
      // console.log({allIns, seats: seats.toJS(), potContributions, potMaxContributions})
    }

    potContributions.forEach((contribution,index) => {
      if(index == 0) {
        displayPots = this._increamentDisplayPots(displayPots, contribution)
      } else {
        displayPots = this._createNextPot(displayPots, contribution)
      }
    })

    return displayPots
  }

  _increamentDisplayPots(displayPots, amount) {
    return displayPots.set(this._currentPot, displayPots.get(this._currentPot) + amount)
  }

  _createNextPot(displayPots, amount) {
    const lastKey = displayPots.keySeq().last()
    const newKey = lastKey.match(/side/) ? `side${parseInt(lastKey.replace('side', ''))+1}` : 'side1'
    this._currentPot = newKey
    return displayPots.set(newKey, amount)
  }
}
