import { html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'


import 'replayer/stylesheets/cashout-chip.scss'

@customElement('cashout-chip')
export class SeatPod extends LitElement {
  @property({ type: String })
  currency = ''

  createRenderRoot() {
    return this
  }

  render() {
    return html`
      <div class="cashout-chip__back"></div>
      <div class="cashout-chip__middle"></div>
      <div class="cashout-chip__face"></div>
    `
  }
}
