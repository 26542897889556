import { LitElement, html, } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import 'replayer/stylesheets/cards.scss'

@customElement('playing-card')
export class PlayingCard extends LitElement {
  @property({ type: Boolean })
  blank = false

  @property({ type: Boolean })
  discarded = false

  @property({ type: String })
  rank = 'x'

  @property({ type: Boolean })
  simple = false

  @property({ type: String })
  suit = 'x'

  @property({ type: Boolean, reflect: true })
  unknown = false

  createRenderRoot() {
    return this
  }

  updated(changedProperties) {
    if (changedProperties.has('rank') || changedProperties.has('suit')) {
      const { rank, suit } = this
      this.unknown = rank == 'x' || suit == 'x'
    }
  }

  get card() {
    const { blank } = this

    if (blank) { return '' }

    return html`
      <div class="playing-card__contents">
        <div class="playing-card__back playing-card__graphic"></div>
        <div class="playing-card__rank playing-card__tint playing-card__graphic"></div>
        <div class="playing-card__suit playing-card__tint playing-card__graphic"></div>
        <div class="playing-card__face playing-card__tint playing-card__graphic"></div>
      </div>
    `
  }

  render() {
    return html`<div data-turbo-cache="false">
      ${this.card}
    </div>`
  }
}
