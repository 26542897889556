import SemanticToggleController from './semantic_toggle_controller'
import { getSetting, saveSetting } from '../lib/settings'

export default class extends SemanticToggleController {
  connect() {
    this.checked = getSetting(this.name)
  }

  get name() {
    return this.data.get('name')
  }

  toggle() {
    super.toggle()
    saveSetting(this.name, this.checked)
  }
}
