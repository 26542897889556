const files = [
  '6-max-hu-lost.json',
  '6-max-no-limit-no-hero.json',
  '6-max-pot-limit-no-hero.json',
  '6-max-short-hero-folded-pre.json',
  'play-money-no-draws.json',
]

const result = {}

files.forEach((k) => {
  result[k] = require(`./${k}`)
})

module.exports = result
