import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    parent: String
  }

  close(e) {
    e.preventDefault()
    const controller = document.getElementById(this.parentValue)['semantic-modal']
    if (controller) {
      controller.close()
    }
  }
}
