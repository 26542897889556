import _ from 'lodash'

export default class {
  constructor(hand) {
    this.hand = hand
    this.sb = hand.sb
    this.bb = hand.bb
    this.ante = hand.ante
    this.buttonBlind = hand.buttonBlind
  }

  /*
   * Finds the first applicable street & index for the provided streets.
   *
   * Assumes streets is an object in street order which are objects contining
   * actions by index.
   *
   * So for example given:
   *
   * {
   *   preflop: {0: {...}},
   *   flop: {5: {...}, 7: {...}}
   * }
   *
   * The following will be returned:
   *
   * _firstStateIndexFor('flop', 999, streets)
   * => {index: 7, street: 'flop', isPreviousState: false, isPreviousStreet: false}
   *
   * _firstStateIndexFor('flop', 6, streets)
   * => {index: 5, street: 'flop', isPreviousState: true, isPreviousStreet: false}
   *
   * _firstStateIndexFor('flop', 4, streets)
   * => {index: 0, street: 'preflop', isPreviousState: true, isPreviousStreet: true}
   *
   */
  _firstStateIndexFor(targetStreet, actionIndex) {
    let streets = this.streets
    if(typeof streets.toJS == 'function') {
      streets = streets.toJS()
    }
    const streetKeys = Object.keys(streets)
    const thisAndPrevious = streetKeys.slice(0, streetKeys.indexOf(targetStreet) + 1).reverse()
    let target
    thisAndPrevious.some((streetKey) => {
      const actions = streets[streetKey]
      if(actions) {
        const isTargetStreet = streetKey == targetStreet
        if(isTargetStreet && actions[actionIndex]) {
          // is the target action
          target = {index: actionIndex, street: streetKey, isPreviousState: false, isPreviousStreet: false}
          return true
        } else {
          // get the previous action from this street
          let indexes = Object.keys(actions).reverse()
          if(isTargetStreet) {
            indexes = _.remove(indexes, (i) => i <= actionIndex)
          }
          if(indexes.length) {
            target = {index: indexes[0], street: streetKey, isPreviousState: true, isPreviousStreet: !isTargetStreet}
            return true
          }
        }
      }
    })

    return target || {index: -1}
  }
}
