import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['menu']

  static values = { visible: { type: Boolean, default: false } }

  connect() {
    useClickOutside(this)
  }

  set visible(visible) {
    this.visibleValue = visible
    const classes = ['active', 'visible']

   classes.forEach((c) => {
      this.element.classList.toggle(c, visible)
    })
    this.menuTarget.classList.toggle('visible', visible)
    this.menuTarget.style = visible ? 'display: block !important' : ''
  }

  toggle(e) {
    if (this.data.get('ignoreMenuClicks') === 'true' && e.target.closest('[data-semantic-dropdown-target="menu"]')) {
      return
    }
    this.visible = !this.visibleValue
  }

  close() {
    this.visible = false
  }
}
