export const getWagerForAction = (action, lastBet, sb, bb, ante, buttonBlind, returnFullRaise = false) => {
  if(typeof action.toJS == 'function') {
    action = action.toJS()
  }
  const val = action.value
  let wagered = 0
  switch(action.action) {
    case 'post':
      if(val == 'SB') {
        wagered = sb
      } else if(val == 'BB') {
        wagered = bb
      } else if(val == 'BUTTON_BLIND') {
        wagered = buttonBlind
      } else if(val == 'ante') {
        wagered = ante
      }
      break
    case 'bet':
    case 'raise':
    case 'call':
    case 'bring_in':
      // raises are parsed with the full amount of the final bet
      if(action.action == 'raise' && !returnFullRaise) {
        wagered = val - lastBet
      } else {
        wagered = val
      }
      break
    case 'return_uncalled':
      wagered = val * -1
      break
  }
  return wagered
}

export const isWagerAction = (action) => {
  if(typeof action.toJS == 'function') {
    action = action.toJS()
  }
  return ['post','bet','raise','call','bring_in','return_uncalled'].indexOf(action.action) != -1
}
